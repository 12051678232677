import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';

import { 
  Form,
  Input,
  notification,
  Cascader,
  Select,
  Row,
  Col,
  Spin,
  Button,
  Modal,
  Breadcrumb,
  DatePicker,
  Card, 
  TimePicker} from "antd";

import { bindActionCreators } from "redux";
import * as erpActions from "../../../redux/actions/erp-actions";

const { Option } = Select;


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const descFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};

class EditTask extends Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      doctype: "Task",
      data: "",
      new: false,
      loading: true,
      showArSettings:false
    };
  }

  async componentDidMount() {
    let data = "";
    let data_ar = {};

    const { params: { name } } = this.props.match;
    
    if(name) {
      await this.props.getDoc(
        this.state.doctype,
        name
      ).then(resp => {
        data = resp.docs[0]
        if(data.exp_start_date) {
          data.exp_start_date = moment(data.exp_start_date, "YYYY-MM-DD")
        }
        if(data.exp_end_date) {
          data.exp_end_date = moment(data.exp_end_date, "YYYY-MM-DD")
        }
        if(data.expected_start_time) {
          data.expected_start_time = moment(data.expected_start_time, "HH:mm:ss")
        }
        
      //}).then(resp => {
        
        if(data.auto_repeat) {
          this.props.getDoc(
            'Auto Repeat',
            data.auto_repeat
          ).then(resp => {
            data_ar = resp.docs[0]
            data.repeat = data_ar.frequency
            if(data_ar.disabled) {
              data.repeat = "";
            }
            if(data_ar.start_date) {
              data.repeat_start = moment(data_ar.start_date, "YYYY-MM-DD")
            }
            if(data_ar.end_date) {
              data.repeat_end = moment(data_ar.end_date, "YYYY-MM-DD")
            }
            this.setState({data_ar: data_ar});
            
            
            this.formRef.current.setFieldsValue(data);
            //var event = new Event('select', { bubbles: true });
            
            if(this.formRef.current.getFieldValue('repeat') == ""){
              this.setState({showArSettings: false});
            }else{
              this.setState({showArSettings: true});
            }
            
            
          }).catch(ex => {
        
          });
        }
        
      }).catch(ex => {
        
      });

      document.title = "Edit " + this.state.doctype + ": " + name;

    } else {
      data = {
        "docstatus": 0,
        "doctype": this.state.doctype,
        //"name": "New " + this.state.doctype,
        "__islocal":1,
        "__unsaved":1,
      };

      document.title = "Create " + this.state.doctype;
    }

    if(typeof data.exp_start_date === "undefined")
      data.exp_start_date = ""
    
    if(typeof data.exp_end_date === "undefined")
      data.exp_end_date = ""

    if(typeof data.expected_start_time === "undefined")
      data.expected_start_time = ""

    if(typeof data.description === "undefined")
      data.description = ""

    this.formRef.current.setFieldsValue(data);
    
    
    this.setState({
      data: data,
      data_ar:data_ar,
      loading: false,
      new: ((name) ? false : true)
    });

  }

  arOnChange = (e,{ name, value }) =>{
    if(value == ""){
      this.setState({showArSettings: false});
    }else{
    this.setState({showArSettings: true});
    }
  }

  createTask = (values) => {
    return new Promise((resolve, reject) => {
      if(this.state.data.name){
        resolve();
        return;
      }
    values.exp_start_date = (values.exp_start_date) ? values.exp_start_date.format("YYYY-MM-DD") : values.exp_start_date
    values.exp_end_date = (values.exp_end_date) ? values.exp_end_date.format("YYYY-MM-DD") : values.exp_end_date

    values.expected_start_time = (values.expected_start_time && values.expected_start_time._isAMomentObject) ? values.expected_start_time.format("HH:mm:ss") : values.expected_start_time

    let newState = {...this.state.data, ...values};
    
    this.props.saveDoc(newState, "Save").then(response => {
      if(response.status === 200) {
        
        this.state.data = response.data.docs[0];
        resolve();
        //setTimeout(() => this.props.history.push(("/dashboard/" + this.state.doctype + "/view/" + response.data.docs[0].name), 1500));
      } else {
        this.setState({ loading: false });
        if( (response.data !== undefined) && (response.data._server_messages !== undefined) ) {
          var message = JSON.parse(JSON.parse(response.data._server_messages.replace(/<\/?[^>]+(>|$)/g, ""))[0]).message;
          debugger;
        } else {
          var message = "Unexpected error occured";
        }
        Modal.warning({
          title: 'Error',
          content: message,
          //onOk: function(){ props.history.push(("/dashboard/" + this.state.doctype); },
        });
        reject();
      }
    },error => {
      this.setState({ loading: false });
      console.log("error", error);
      Modal.warning({
        title: 'Error',
        content: "Not Saved!!",
      });
      reject();
    }).catch(error => {
      this.setState({ loading: false });
      console.log("||Error: ", error);
      Modal.warning({
        title: 'Error',
        content: "Not Saved!",
      });
      reject();
    });
  });
  }

  onFinishAutoRepeatSettings = values => {
    return new Promise((resolve, reject) => {
    this.setState({ loading: true });
    
    this.createTask(values).then(response => {
      
      
    if(this.state.data_ar.name){
      if(this.state.showArSettings){
        this.state.data_ar.disabled = false;
        this.state.data_ar.frequency = values.repeat;
        this.state.data_ar.start_date=values.repeat_start.format("YYYY-MM-DD");
        this.state.data_ar.end_date=values.repeat_end ? values.repeat_end.format("YYYY-MM-DD") : "";
      }else{
        this.state.data_ar.disabled = true;
      }
      
      this.props.saveDoc(this.state.data_ar, "Save").then(response => {
        if(response.status === 200) {
          
          this.state.data_ar = response.data.docs[0];
          this.props.getDoc(
            this.state.doctype,
            this.state.data.name
          ).then(resp => {
            this.state.data = resp.docs[0]
            resolve();
            
          }).catch(ex => {
            
          });
          
          //setTimeout(() => this.props.history.push(("/dashboard/" + this.state.doctype + "/view/" + response.data.docs[0].name), 1500));
        } else {
          this.setState({ loading: false });
          if( (response.data !== undefined) && (response.data._server_messages !== undefined) ) {
            var message = JSON.parse(JSON.parse(response.data._server_messages.replace(/<\/?[^>]+(>|$)/g, ""))[0]).message;
            debugger;
          } else {
            var message = "Unexpected error occured";
          }
          Modal.warning({
            title: 'Error',
            content: message,
            //onOk: function(){ props.history.push(("/dashboard/" + this.state.doctype); },
          });
          reject();
        }
      },error => {
        this.setState({ loading: false });
        console.log("error", error);
        Modal.warning({
          title: 'Error',
          content: "Not Saved!!",
        });
        reject();
      }).catch(error => {
        this.setState({ loading: false });
        console.log("||Error: ", error);
        Modal.warning({
          title: 'Error',
          content: "Not Saved!",
        });
        reject();
      });
       return;
    }else{
        if(!this.state.showArSettings){
          resolve();
          return;
        }
        let params = {}
        params.doctype='Task';
        params.docname=this.state.data.name;
        params.frequency=values.repeat;
        params.start_date=values.repeat_start.format("YYYY-MM-DD");
        params.end_date=values.repeat_end ? values.repeat_end.format("YYYY-MM-DD") : "";

        this.props.makeAutoRepeat(params).then(response => {
          this.state.data_ar = response.message;
          
          resolve("Promise resolved successfully");
          
        },error => {
          this.setState({ loading: false });
          console.log("error", error);
          Modal.warning({
            title: 'Error',
            content: "Not Saved!!",
          });
          reject(Error("Promise rejected"));
        }).catch(error => {
          this.setState({ loading: false });
          console.log("||Error: ", error);
          Modal.warning({
            title: 'Error',
            content: "Not Saved!",
          });
          reject(Error("Promise rejected"));
        });
  }
  });
  });

  }

  onFinish = values => {
    this.onFinishAutoRepeatSettings(values).then( result => {
     
    if(this.state.new && this.state.data.name){
        this.props.history.push(("/dashboard/" + this.state.doctype + "/view/" + this.state.data.name), 1500);
        return;
    }

    this.setState({ loading: true });
    

    values.exp_start_date = (values.exp_start_date) ? values.exp_start_date.format("YYYY-MM-DD") : values.exp_start_date
    values.exp_end_date = (values.exp_end_date) ? values.exp_end_date.format("YYYY-MM-DD") : values.exp_end_date

    values.expected_start_time = (values.expected_start_time && values.expected_start_time._isAMomentObject) ? values.expected_start_time.format("HH:mm:ss") : values.expected_start_time

    let newState = {...this.state.data, ...values};
    
    this.props.saveDoc(newState, "Save").then(response => {
      if(response.status === 200) {
        notification['success']({
          message: 'Data Saved',
          description: '',
        });debugger;
        setTimeout(() => this.props.history.push(("/dashboard/" + this.state.doctype + "/view/" + response.data.docs[0].name), 1500));
      } else {
        this.setState({ loading: false });
        if( (response.data !== undefined) && (response.data._server_messages !== undefined) ) {
          var message = JSON.parse(JSON.parse(response.data._server_messages.replace(/<\/?[^>]+(>|$)/g, ""))[0]).message;
          debugger;
        } else {
          var message = "Unexpected error occured";
        }
        Modal.warning({
          title: 'Error',
          content: message,
          //onOk: function(){ props.history.push(("/dashboard/" + this.state.doctype); },
        });
      }
    },error => {
      this.setState({ loading: false });
      console.log("error", error);
      Modal.warning({
        title: 'Error',
        content: "Not Saved!!",
      });
    }).catch(error => {
      this.setState({ loading: false });
      console.log("||Error: ", error);
      Modal.warning({
        title: 'Error',
        content: "Not Saved!",
      });
    });
  }, function(error) {
    Modal.warning({
      title: 'Error',
      content: "Not Saved!",
    });
 });
  };

  onFinishFailed = ({ errorFields }) => {
    this.formRef.current.scrollToField(errorFields[0].name);
  };


  render() {
    const { visible, loading } = this.state;
    return (
      <Spin spinning={this.state.loading}>
        <div className="main-content">
          <div className="create-task-section">
            <Row gutter={24} className="page-header-section">
              <Col className="page-title">
                <Breadcrumb>
                  <Breadcrumb.Item><Link to={"/dashboard/" + this.state.doctype}><h1>Task</h1></Link></Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <span>{(this.state.new) ? "Create Task" : "Edit " + ((this.state.data.name) ? this.state.data.name : "Task")}</span>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            <Card>
              <Form ref={this.formRef} {...formItemLayout} onFinish={this.onFinish} onFinishFailed={this.onFinishFailed} initialValues={{layout: tailFormItemLayout}}>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="repeat"
                        label="Repeat"
                      >
                        <Select  onChange={this.arOnChange}> 
                          <Option value="">No Repeat</Option>
                          <Option value="Every 3 Hours">Every 3 Hours</Option>
                          <Option value="Daily">Daily</Option>
                          <Option value="Weekly">Weekly</Option>
                          <Option value="Two weeks">Two weeks</Option>
                          <Option value="Monthly">Monthly</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {this.state.showArSettings && (
                      <React.Fragment>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="repeat_start"
                        label="Start"
                        rules={[
                          {
                            required: true,
                            message: 'Start Date is required!',
                          },
                        ]}
                      >
                         <DatePicker />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="repeat_end"
                        label="End"
                        rules={[
                          {
                            required: false,
                            message: 'End Date is required!',
                          },
                        ]}
                      >
                         <DatePicker />
                      </Form.Item>
                    </Col></React.Fragment>
                    )}
                </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="subject"
                        label="Subject"
                        rules={[
                          {
                            required: true,
                            message: 'Subject is required!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter Subject"/>
                      </Form.Item>
                    </Col>
                    
                    {(!this.state.new) ? <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item name="status" label="Status">
                        <Select allowClear>
                          <Option value="Open">Open</Option>
                          <Option value="Completed">Completed</Option>
                          <Option value="Cancelled">Cancelled</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    : ""}

                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item name="type" label="Activity Type">
                        <Select allowClear>
                          <Option value="Floor Cleaning">Floor Cleaning</Option>
                          <Option value="Coffee Machine Cleaning">Coffee Machine Cleaning</Option>
                          <Option value="Fridge Loading">Fridge Loading</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="exp_start_date"
                        label="Expected Start Date"
                      >
                        <DatePicker />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="expected_start_time"
                        label="Expected Start Time"
                      >
                        <TimePicker />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="exp_end_date"
                        label="Expected End Date"
                      >
                        <DatePicker />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="priority"
                        label="Priority"
                      >
                        <Select allowClear>
                          <Option value="Low">Low</Option>
                          <Option value="Medium">Medium</Option>
                          <Option value="High">High</Option>
                          <Option value="Urgent">Urgent</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} className="align-left-5">
                      <Form.Item 
                      {...descFormItemLayout}
                        name="description" 
                        label="Description"
                      >
                        <Input.TextArea placeholder="Write here..." />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item 
                  {...tailFormItemLayout}
                  >
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
          </div>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...erpActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    EditTask
  )
);

