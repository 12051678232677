import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactTimeAgo from 'react-time-ago';

import { 
  Table, Divider, Tag,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  Card } from "antd";

// import Axios from "axios";
import { bindActionCreators } from "redux";
import * as erpActions from "../../../redux/actions/erp-actions";

import {ReactComponent as EditIcon} from "../../../images/edit.svg";
import {ReactComponent as ViewIcon} from "../../../images/eye.svg";
import { ExclamationCircleOutlined } from '@ant-design/icons';



class DocListing extends Component {

  state = {
    loading: false,
    doctype: "Issue",
    fields: ["subject", "name", "raised_by", "owner", "creation", "modified", "modified_by", "status", "docstatus"],
    data: [],
    pagination: {
      pageSize: 10,
      current: 1,
      hideOnSinglePage: true,
      showTotal: (total, range) => <span>Showing {range[0]}-{range[1]} of {total}</span>
    },
    sorter: {
      field: "modified",
      order: "descend"
    },
    filters: [],
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    document.title = "All Ticket";// + this.state.doctype;
    if (this.state.loading === false) {
      this.setState({
        loading: true
      });
      this.handleTableChange(this.state.pagination, {}, this.state.sorter);
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      pagination: pagination,
      sorter: sorter,
      loading: true
    });

    this.props.getCount({
      doctype: this.state.doctype,
    }).then(resp => {
      const pagination = { ...this.state.pagination };
      pagination.total = resp.total_count;
      this.setState({pagination});

      this.props.getList({
        doctype: this.state.doctype,
        fields: "[" + this.state.fields.map(field => "\"`tab" + this.state.doctype + "`.`" + field + "`\"") + "]",
        filters: this.state.filters,
        order_by: (sorter.field) ? "`tab" + this.state.doctype + "`.`" + sorter.field + "` " + ((sorter.order === "ascend") ? "asc" : "desc") : "",
        start: pagination.pageSize * (pagination.current - 1),
        page_length: pagination.pageSize,
        with_comment_count: true,
      }).then(resp => {
        this.setState({
          loading: false,
          data: resp,
        });
      }).catch(ex => {
        this.setState({ loading: false });
      });
    }).catch(ex => {
      this.setState({ loading: false });
    });
  };

render() {


   const columns = [
     {
       title: "Subject",
       dataIndex: "subject",
       key: "subject",
       sorter: true,
     },
     {
       title: "Status",
       dataIndex: "status",
       key: "status",
     },
     {
       title: "Raised By",
       dataIndex: "raised_by",
       key: "raised_by",
     },
     {
       title: "ID",
       dataIndex: "name",
       key: "name",
       sorter: true,
     },
     {
       title: "Created",
       dataIndex: "creation",
       key: "creation",
       sorter: true,
       //render: (text) => <ReactTimeAgo date={text} locale="en"/>,
       render: (text, record) => (         
         <ReactTimeAgo date={this.props.convertToCurrentTimezone(record.creation).substr(0, 16).replace(/-/g, "/")} locale="en"/>
       ),
     },
     {
       title: "Modified",
       dataIndex: "modified",
       key: "modified",
       sorter: true,
       //render: (text) => <ReactTimeAgo date={text} locale="en"/>,
       render: (text, record) => (        
         <ReactTimeAgo date={this.props.convertToCurrentTimezone(record.modified).substr(0, 16).replace(/-/g, "/")} locale="en"/>
       ),
       //width: "15%",
     },
     {
       title: 'Actions',
       key: 'action',
       render: (text, record) => ( 
        <span>
          <div className="actionbtngroup">
            <Link to={"/dashboard/" + this.state.doctype + "/edit/"+record.name}><button className="edit-btn"><EditIcon /></button></Link>
            <Link to={"/dashboard/" + this.state.doctype + "/view/"+record.name}><button className="view-btn"><ViewIcon /></button></Link>
          </div>
        </span>
       ),
       //width: "10%",
     },
   ];

   return (
     <div className="main-content">
        <div className="doc-section">
        <Row gutter={24} className="page-header-section">
            <Col className="page-title"><h1>Ticket</h1></Col>
            <Col>
              <Link to="/dashboard/Issue/new"><Button type="primary" size="small">Create Ticket</Button></Link>
            </Col>
          </Row>          
          <div className="doc-list">
          <Table 
            columns = {columns}
            bordered
            rowKey = {record => record.orderId}
            dataSource = {this.state.data}
            pagination = {this.state.pagination}
            loading = {this.state.loading}
            onChange = {this.handleTableChange}
          />
          </div>
        </div>
      </div>

   );
 }

}

function mapStateToProps(state) {
  return {
    ...state.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...erpActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    DocListing
  )
);

