import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { bindActionCreators } from "redux";
import Logo from "../../images/logo.png";
import * as authActions from  "../../redux/actions/auth-actions";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date()
    };
    this.handelLogout = this.handelLogout.bind(this);
  }

  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      time: new Date()
    });
  }

  handelLogout(){
    this.props.logout();
    this.props.history.push('/')
  }

  render() {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    var date =
      days[this.state.time.getDay()] +
      ", " +
      months[this.state.time.getMonth()] +
      " " +
      (this.state.time.getDate() < 10 ? "0" : "") +
      this.state.time.getDate() +
      ", " +
      this.state.time.getFullYear();
    var time =
      (this.state.time.getHours() < 10 ? "0" : "") +
      this.state.time.getHours() +
      ":" +
      (this.state.time.getMinutes() < 10 ? "0" : "") +
      this.state.time.getMinutes() +
      ":" +
      (this.state.time.getSeconds() < 10 ? "0" : "") +
      this.state.time.getSeconds();

    return (
      <div className="wrap">
        <div className="top-header">
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <ul className="date">
                <li>
                  <i class="far fa-calendar-alt"></i><span>{date}</span>
                </li>
              </ul>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} style={{ textAlign: "right" }}>
              <ul className="top-menu">
                <li>
                  <Link to="/dashboard/Task/History">Activity History</Link>
                </li>
                <li>
                  <Link to="#" onClick={this.handelLogout}>
                    Log Out <i class="fas fa-sign-out-alt"></i>
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        <div className="header-clocks">
          <Row gutter={24}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Link to="/dashboard/Task"><img src={Logo} alt="logo" /></Link>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "right" }}>
              <ul className="time">
                <li style={{fontFamily: "monospace"}}>
                  <i class="fas fa-clock"></i><span>{time}</span>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...authActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps)(
    Header
  )
);

