import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {  Menu } from "antd";
import { CloudUploadOutlined, VideoCameraOutlined, UserOutlined } from '@ant-design/icons';
class Sidebar extends Component {
  render() {
    return (
      <div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1">
          <UserOutlined />
            <span>
              <Link to="/projects">Projects</Link>
            </span>
          </Menu.Item>
          <Menu.Item key="2">
          <VideoCameraOutlined />
            <span>nav 2</span>
          </Menu.Item>
          <Menu.Item key="3">
          <CloudUploadOutlined />
            <span>nav 3</span>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

export default withRouter(connect(null, null)(Sidebar));
