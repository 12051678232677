import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as erpActions from "../../redux/actions/erp-actions";
import * as authActions from  "../../redux/actions/auth-actions";

import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Layout, Menu, Breadcrumb, Col, Avatar, Image, Badge, Dropdown } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CaretDownFilled,
  SettingOutlined,
  HomeOutlined,
  DeploymentUnitOutlined,
  IdcardOutlined,
} from '@ant-design/icons';

import Logo from "../../images/logo.png";
import Profile from "../../images/profile-img.png";
import BellIcon from "../../images/notifications.png";

import * as sidebarAction from "../../redux/actions/sidebar-actions";
import DashboardRouter from "../../routes/dashboard-router.js";
import { routes }  from "../../routes/dashboard-router.js";
import {Location,matchPath} from 'react-router'


const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
/* const profilemenu = (
  <Menu className="profile-submenu">
    <Menu.Item key="0">
      <a href="/profile">User Profile</a>
    </Menu.Item>
    <Menu.Item key="1">
      <a href="">Logout</a>
    </Menu.Item>
  </Menu>
); */

class Dashboard extends Component {

  state = {
    collapsed: false,
  };

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handelLogout = () => {
    this.props.logout();
    this.props.history.push('/')
  }

  render() { 
    const { url } = this.props.match;
    let routes2 = Object.values(routes).reduce((routes2, thing) => {
      if (['Task','Issue',"","TeamReview"].includes(thing.path)) {
        routes2.push(thing);
      }
      return routes2;
    }, []);
    let longestRoutes = Object.values(routes2).sort( (a, b) =>
          b.path.length - a.path.length      
    );
    //console.log(routes);
    //console.log(longestRoutes);
    // MyMenu component
    const getMatchedKey = (l) => {      
      return (        
        '/dashboard/'+(Object.values(longestRoutes).find((route) =>
            matchPath(l.pathname.replace('/dashboard/',''), route.path)
        ).path || '')
      );
    } 
   // console.log(getMatchedKey(window.location)+'=='+matchPath(window.location.pathname.replace('/dashboard/','/'),'/').path)
    
   const { user } = this.props;
   const profilemenu = (
    <Menu className="profile-submenu">
      <Menu.Item key="0">
        <a href="/profile">User Profile</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="#" onClick={this.handelLogout}>Logout</a>
      </Menu.Item>
    </Menu>
  );
    return (
      <div className="wrap dashboard">
        <Layout style={{ minHeight: '100vh' }}>
          <Sider 
            trigger={null} collapsible
            style={{flex: '0 0 250px', maxWidth: '250px', minWidth: '250px', width: '250px'}}
            breakpoint="md"
            collapsedWidth="0"
            onBreakpoint={broken => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            collapsed={this.state.collapsed} onCollapse={this.onCollapse} 
          >
            <div className="logo" >
                <Link to="/dashboard"><img src={Logo} alt="logo" style={{width: '130px'}}/></Link>
            </div>
            <Menu 
              theme="light" 
              defaultSelectedKeys={['/dashboard']} 
              //selectedKeys={[window.location.pathname]} 
              selectedKeys={[getMatchedKey(window.location)]}
              mode="inline"
            >
              <Menu.Item key="/dashboard/">
                <Link to="/dashboard">Home</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/Task">
                <Link to="/dashboard/Task">Task</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/Issue">
                <Link to="/dashboard/Issue">Ticket</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/TeamReview">
                <Link to="/dashboard/TeamReview">Team Review</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background">
              {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: this.toggle,
              })}
              <Menu style={{float: 'right'}} theme="light" mode="horizontal" defaultSelectedKeys={['2']} className="" >
                <Menu.Item className="bell-icon">
                  <span className="avatar-item">
                    <Badge count={1}>
                      <Avatar src={<Image src={BellIcon} alt="Bell Icon" />} />
                    </Badge>
                  </span>
                </Menu.Item>
                  <Dropdown overlay={profilemenu} trigger={['click']} className="user-profile">
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                      
                    {(user.user_image) ? <Avatar src={(user.user_image.indexOf("http") === 0) ? user.user_image : "/erp/" + user.user_image} /> : <Avatar>{user.employee_name.match(/\b(\w)/g).join('').toUpperCase()}</Avatar>}
                      <CaretDownFilled />
                    </a>
                  </Dropdown>
              </Menu>
            </Header>
            <Content style={{ margin: '16px' }}>
              <div className="site-layout-background" style={{ padding: 12, minHeight: 360 }}>
                <DashboardRouter url={url} />
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

Dashboard.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};
function mapStateToProps(state) {
  const {  auth } = state;
    const { user } = auth;
    
  return {
    user,
    ...state.sidebar
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...sidebarAction, ...erpActions,...authActions  }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    Dashboard
  )
);
