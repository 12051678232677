import {
    Types
} from "../constants/auth-types";

var initialState = {
    user : {}
};

if( localStorage.getItem("emp_id")){
    var initialState = {
        user : {
            'emp_id': localStorage.getItem("emp_id"),
            'employee_name': localStorage.getItem("employee_name"),
            'first_name': localStorage.getItem("employee_first_name"),
            //'date_of_birth': localStorage.getItem("date_of_birth"),
            'email': localStorage.getItem("email"),
            'user_image': localStorage.getItem("user_image"),
        }
    };
}

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case Types.LOGIN:
            state.user = action.payload;
            return {
                ...state, 
            };
        case Types.LOGOUT:
            state.user = {};
            return {
                ...state, 
            };
        default:
            return state;
    }
}