import React, { Component } from "react";

import { Select, Spin, AutoComplete, Input } from 'antd';
import debounce from 'lodash/debounce';
import * as erpActions from "../../redux/actions/erp-actions";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import API from "../../redux/api/frapee-client";

const { Option } = Select;

class ErpSelectSearch extends Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchData = debounce(this.fetchData, 800);
    
  }

  state = {
    data: [],
    value: [],
    fetching: false,
  };

  componentDidMount() {
      this.setState({ value: this.props.defaultValue });
  }

  fetchData = value => {
    console.log('fetching data', value);
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });

    this.props.searchLink({
        doctype: this.props.doctype,
        txt: value,
        filters: (this.props.filters) ? this.props.filters : "[]",
      }).then(resp => {
        const data = resp.results.map(row => ({
            text: ((typeof(this.props.showDescription) !== undefined) && this.props.showDescription) ? `${row.description}` : `${row.value}`,
            value: `${row.value}`,
        }));
        // if(data.length === 0){
        //     data = null;
        // }
        this.setState({
          data: data,
        });
      })
      .catch(ex => {
        //this.setState({ loading: false });
      });
  };

    

  handleChange = value => {
    this.setState({
      value,
      data: [],
      fetching: false,
    });

    if((this.props.form !== undefined) && (this.props.form !== "")) {
      this.props.form.setFieldsValue({
        [this.props.name]: value
      })
    }

  };
  

  render() {
    
    //const {getFieldDecorator} = this.props.form;
    const { fetching, data, value } = this.state;

    // return (
    //   <div>
    //     <Input name="country"/>
    //   </div>
    // );
    
    return (
      <AutoComplete
        dataSource={data}
        defaultValue={this.props.value}
        key={this.props.value}
        value={value}
        filters={this.props.filters}
        searchText={this.props.searchText}
        onSelect={this.props.onSelect}
        notFoundContent={fetching ? <Spin size="small" /> : "Select " + this.props.doctype}
        onSearch={this.fetchData}
        onClick={this.fetchData}
        //={this.onInputChange}
        onChange={this.handleChange}
        placeholder={this.props.placeholder}
        ref={this.props.ref}
        name={this.props.name}
        children={this.props.children}
      />
    );
    
    
  }
}

function onSelect(value) {
    console.log('onSelect', value);
  }

function mapStateToProps(state) {
    return {
      ...state.user
    };
  }

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
      ErpSelectSearch
    )
  );

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ...erpActions }, dispatch);
  }
