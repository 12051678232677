import React, { Component } from "react";
import {
  Form,
  Input,
  Tag,
  Cascader,
  Spin,
  Row,
  Col,
  Descriptions,
  Button,
  Modal,
  Breadcrumb,
  Card,
  message
} from 'antd';
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as erpActions from "../../../redux/actions/erp-actions";
import ErpSelectSearch from "../../erpnext/select-search";
import { PlusOutlined } from '@ant-design/icons';

class ViewTask extends Component {

  add_assignee_user_id = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      docinfo: {},
      doctype: "Task",
      auto_repeat: {},
      name: "",
      visible_assignee_popup: false,
      shifts: {},
      assignments: [],
    };
    
    const { params: { name } } = props.match;

    document.title = "View " + this.state.doctype + ": " + name;

    this.setState({name: name, loading: true});

    this.props.getDoc(
        this.state.doctype,
        name
      ).then(resp => {
        //get auto-repeat
        if(typeof(resp.docs[0].auto_repeat) !== "undefined" && resp.docs[0].auto_repeat){
          this.props.getDoc(
            "Auto Repeat",
            resp.docs[0].auto_repeat
          ).then(resp => {
            this.setState({
              auto_repeat: resp.docs[0]
            });
          })
        }

        //get shifts
        if(resp.docs[0].expected_start_time){
          let fields = ['name', 'start_time', 'end_time']
          this.props.getList({
            doctype: "Shift Type",
            fields: "[" + fields.map(field => "\"`tab" + "Shift Type" + "`.`" + field + "`\"") + "]",
            page_length: "100",
          }).then(shift_resp => {
            this.setState({
              shifts: shift_resp,
            });
          }).catch(ex => {
            
          });
        }


        this.setState({
          loading: false,
          data: resp.docs[0],
          docinfo: resp.docinfo
        });
        if((this.state.docinfo.assignments !== undefined) && this.state.docinfo.assignments.length){

        this.state.docinfo.assignments.forEach( (assignee) => (
          
           // e.preventDefault(); 
            
        this.props.searchLink({
          txt:assignee.owner,
          doctype:'User',
          reference_doctype:'',
          query:'frappe.core.doctype.user.user.user_query',
          filters:'{"user_type":"System User"}'
        }).then(resp => {
          //console.log(resp);
          this.setState({
            assignments: [ ...this.state.assignments, {'owner':assignee.owner, 'name':resp.results[0].description}]     
          })
          console.log(this.state.assignments)
        })

         ));
      }
      
      }).catch(ex => {
        this.setState({ loading: false });
      });
  }

  removeAssignee = (user_id) => {
    Modal.confirm({
      title: 'Confirm',
      //icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to remove the Assignee?',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: () => {
        this.setState({loading: true});
        this.props.assignToRemove({
          'assign_to': user_id,
          'doctype': this.state.doctype,
          'name': this.state.data.name,
        }).then(response => {
          this.setState({loading: false});
          if((typeof(response.error) !== undefined) && (response.error)){
            Modal.error({
              title: 'Error',
              content: "Please try again",
            });
          } else {
            Modal.success({
              title: 'Success',
              content: "Assignee remove successfully",
            });
            setTimeout(() => window.location.reload(false), 2000)
          }

          //setTimeout(() => this.props.history.push('/dashboard/Task'));
        })
      }
    });

    return true

  }

  addAssignee = () => {
    let user_id = this.add_assignee_user_id.input.value;
    if(user_id === "") {
      message.error('Please enter the User ID.', 3.8);
    } else {
      this.setState({loading: true});
      this.props.assignTo({
        'assign_to': user_id,
        'doctype': this.state.doctype,
        'name': this.state.data.name,
      }).then(response => {
        this.setState({loading: false});
        if((typeof(response.error) !== undefined) && (response.error)){
          Modal.error({
            title: 'Error',
            content: "Please try again",
          });
        } else {
          Modal.success({
            title: 'Success',
            content: "Assignee added successfully",
          });
          setTimeout(() => window.location.reload(false), 2000)
        }
      })
    }
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible_assignee_popup: false,
    });
  };

  render() {

    return (
      <div id="select-process" className="main-content">
        <div className="create-task-section">
          <Row gutter={24} className="page-header-section">
            <Col className="page-title">
              <Breadcrumb>
                <Breadcrumb.Item><Link to={"/dashboard/" + this.state.doctype}><h1>Task</h1></Link></Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>View Task</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            {(this.state.data.name) && (
              <Col>
              <Link to={"/dashboard/Task/edit/" + this.state.data.name}><Button type="primary" size="small">Edit Task</Button></Link>
            </Col>
              )}
          </Row>
          <Card>

          <Spin spinning={this.state.loading}>
          <Descriptions title="Details" labelStyle={{fontWeight: "600"}} contentStyle={{color: "#74767a"}}>
            <Descriptions.Item label="Subject">{this.state.data.subject}</Descriptions.Item>
            <Descriptions.Item label="Status">{this.state.data.status}</Descriptions.Item>
            {/* <Descriptions.Item label="ID">{this.state.data.name}</Descriptions.Item> */}
            <Descriptions.Item label="Activity Type">{this.state.data.type}</Descriptions.Item>
            <Descriptions.Item label="Priority">{this.state.data.priority}</Descriptions.Item>
            <Descriptions.Item label="Expected Start Date">{this.state.data.exp_start_date}</Descriptions.Item>
            <Descriptions.Item label="Expected End Date">{this.state.data.exp_end_date}</Descriptions.Item>
            <Descriptions.Item label="Expected End Time">{this.state.data.expected_start_time}</Descriptions.Item>
            
            <Descriptions.Item label="Task Frequency">{(this.state.auto_repeat.frequency ? this.state.auto_repeat.frequency : "None")}</Descriptions.Item>
            <Descriptions.Item label="Description">{this.state.data.description}</Descriptions.Item>
          </Descriptions>
          <Descriptions title="Assignments" labelStyle={{fontWeight: "600"}} contentStyle={{color: "#74767a"}}>
            <Descriptions.Item label="Assigned To">
              {((this.state.assignments !== undefined) && this.state.assignments.length) ? this.state.assignments.map(assignee => <Tag closable onClose={(e) => {e.preventDefault(); return this.removeAssignee(assignee.owner)}}>{assignee.name}</Tag>) : <Tag>No Assignee</Tag>}
              {<Tag onClick={() => this.setState({visible_assignee_popup: true})} className="site-tag-plus"><PlusOutlined /> New Assignee</Tag>}
            </Descriptions.Item>
          </Descriptions>

          <Modal
              visible={this.state.visible_assignee_popup}
              onOk={this.addAssignee}
              onCancel={this.handleCancel}
              footer={null}
              className="assignto-popup"
            >
              <div className="ant-modal-header">
                <div className="ant-modal-title" id="rcDialogTitle0">Assign To</div>
                <div className="btn-group">
                  <Button key="submit" type="primary" loading={this.state.loading} onClick={this.addAssignee} size="small">
                    Add
                  </Button>
                  <Button key="back" onClick={this.handleCancel} size="small">
                    Close
                  </Button>
                </div>
              </div>
              <Form name="assignto">
                <Form.Item name="email" label="User">
                  <ErpSelectSearch showDescription={1} children={<Input name="add_assignee_user_id"  ref={(Input) => { this.add_assignee_user_id = Input; }}/>} placeholder="Select User" doctype="User" searchfield="reference_number" query="frappe.core.doctype.user.user.user_query" descriptionIndex={1} filters='{"user_type":"System User"}'/>
                </Form.Item>
              </Form>
            </Modal>
            </Spin>
            </Card>
        </div>
      </div>
    );
  }
}


ViewTask.defaultProps = {
  showActionTab: true
};

function mapStateToProps(state) {
  return {
    ...state.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...erpActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ViewTask
  )
);