import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Layout,Menu,Modal,notification,Spin } from 'antd';

import { 
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  Breadcrumb,
  Card,Upload } from "antd";

import { bindActionCreators } from "redux";
import * as erpActions from "../../../redux/actions/erp-actions";

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getBase64 } from "../../../utilities";

const issueStatus = [
  {
    value: 'Open',
    label: 'Open',
  },
  {
    value: 'Replied',
    label: 'Replied',
  },
  {
    value: 'Escalate',
    label: 'Escalate',
  },
  {
    value: 'Hold',
    label: 'Hold',
  },
  {
    value: 'Closed',
    label: 'Closed',
  },
];
const issuePriority = [
  {
    value: 'Low',
    label: 'Low',
  },
  {
    value: 'medium',
    label: 'Medium',
  },
  {
    value: 'high',
    label: 'High',
  },  
  {
    value: 'asap',
    label: 'ASAP',
  },
];
const activity = [
  {
    value: 'floor Cleaning',
    label: 'floor Cleaning',
  },
  {
    value: 'Plantation',
    label: 'Plantation',
  },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const descFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};

class EditIssue extends Component {
  
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      doctype: "Issue",
      data: "",
      new: true,
      loading: true,
      collapsed: false,
      previewVisible: false,
    previewImage: '',
    previewFileUrl:'',
    previewTitle: '',
    fileList: [
      
    ],
    };
  }

  async componentDidMount() {
    let data = "";

    const { params: { name } } = this.props.match;
    
    if(name) {
      await this.props.getDoc(
        this.state.doctype,
        name
      ).then(resp => {
        data = resp.docs[0]
        var newArray = this.state.fileList.slice();  
        resp.docinfo.attachments.forEach(fileObj => {
          newArray.push( {
            uid: fileObj.name,
            name: fileObj.file_name,
            status: 'done',
            url: 'erp/'+fileObj.file_url,
          }); 
        });          
        this.setState({fileList:newArray})
      }).catch(ex => {
        
      });

      document.title = "Edit " + this.state.doctype + ": " + name;

    } else {
      data = {
        "docstatus": 0,
        "doctype": this.state.doctype,
        "name": "New " + this.state.doctype,
        "__islocal":1,
        "__unsaved":1,
      };

      document.title = "Create " + this.state.doctype;
    }

    

    if(typeof data.description === "undefined")
      data.description = ""

    this.formRef.current.setFieldsValue(data);

    this.setState({
      data: data,
      loading: false,
      new: ((name) ? false : true)
    });
  }

  onFinish = values => {

    this.setState({ loading: true });
    

    let newState = {...this.state.data, ...values};

    this.props.saveDoc(newState, "Save").then(response => {
      if(response.status === 200) {
        notification['success']({
          message: 'Data Saved',
          description: '',
        });
        setTimeout(() => this.props.history.push(("/dashboard/" + this.state.doctype + "/view/" + response.data.docs[0].name), 1500));
      } else {
        this.setState({ loading: false });
        if( (response.data !== undefined) && (response.data._server_messages !== undefined) ) {
          var message = JSON.parse(JSON.parse(response.data._server_messages)[0]).message;
        } else {
          var message = "Unexpected error occured";
        }
        Modal.warning({
          title: 'Error',
          content: message,
          //onOk: function(){ props.history.push(("/dashboard/" + this.state.doctype); },
        });
      }
    },error => {
      this.setState({ loading: false });
      console.log("error", error);
      Modal.warning({
        title: 'Error',
        content: "Not Saved!!",
      });
    }).catch(error => {
      this.setState({ loading: false });
      console.log("||Error: ", error);
      Modal.warning({
        title: 'Error',
        content: "Not Saved!",
      });
    });
  };

  onFinishFailed = ({ errorFields }) => {
    this.formRef.current.scrollToField(errorFields[0].name);
  };

  handlecustomRequest = info => {
    try{
      console.log(info)
      this.props.uploadFile({name:info.file.name, file:info.file, doctype:'Issue',docname:this.state.data.name}).then(response => {
        var data = {...this.state.data};
        data.image = response.data.message.file_url;
        var fileObj = response.data.message;
        var newArray = this.state.fileList.slice();    
        newArray.push( {
          uid: fileObj.name,
          name: fileObj.file_name,
          status: 'done',
          url: 'erp/'+fileObj.file_url,
        });   
    this.setState({fileList:newArray})
        
        this.setState({ loading: false });
      },error => {
        this.setState({ loading: false });
        console.log("error", error);
        Modal.warning({
          title: 'Error',
          content: "Not Saved!!",
        });
      }).catch(error => {
        this.setState({ loading: false });
        console.log("||Error: ", error);
        Modal.warning({
          title: 'Error',
          content: "Not Saved!",
        });
      });
      
    } catch (e) {
      throw e;
    }
  }

  handleUploadChange = info => {
    if (info.file.status === 'uploading') {
      //console.log(info.file)
      this.setState({ loading: true });
      
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  };
  handleUploadCancel = () => this.setState({ previewVisible: false });

  handleUploadPreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

 

    this.setState({
      previewImage: file.name.match(/\.(jpg|jpeg|png|gif)$/) ? (file.url || file.preview) : null,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      previewFileUrl:file.url
    });
  };

  render() {
    const { previewVisible, previewImage, fileList, previewTitle,previewFileUrl } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Spin spinning={this.state.loading}>
      <div className="main-content">
        <div className="add-ticket-section">
          <Row gutter={24} className="page-header-section">
            <Col className="page-title">
              <Breadcrumb>
              <Breadcrumb.Item><Link to={"/dashboard/" + this.state.doctype}><h1>Ticket</h1></Link></Breadcrumb.Item>
                
                <Breadcrumb.Item>
                    <span>{(this.state.new) ? "Create Task" : "Edit " + ((this.state.data.name) ? this.state.data.name : "Ticket")}</span>
                  </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Card>
              <Form
                ref={this.formRef}                
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed} 
                initialValues={{layout: tailFormItemLayout, name: this.state.data.name}}
                {...formItemLayout}
                // form={form}
                name="register"
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      name="subject"
                      label="Subject"
                    >
                      <Input placeholder="Lorem Ispum"/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      name="status"
                      label="Status"
                    >
                      <Select options={issueStatus} placeholder="Open" />
                    </Form.Item>
                  </Col>
                  
                  
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      name="issue_type"
                      label="Type"
                    >
                      <Select options={activity} placeholder="Floor CLeaning" />
                    </Form.Item>
                  </Col>           
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      name="priority"
                      label="Priority"
                    >
                      <Select options={issuePriority} placeholder="Medium" />
                    </Form.Item>
                  </Col>   
                  
                  
                  <Col xs={24} sm={24} md={24} lg={24} className="align-left-5">
                    <Form.Item 
                    {...descFormItemLayout}
                      name="description" 
                      label="Description"
                    >
                      <Input.TextArea placeholder="Write here..." />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} className="align-left-5">
                    Attachments
                    <Upload
          customRequest={this.handlecustomRequest}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handleUploadPreview}
          onChange={this.handleUploadChange}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleUploadCancel}
        >
          {
          (previewImage) ? 
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
          :
          <iframe src={previewFileUrl} style={{ width: '100%' }}/>
  }
        </Modal>
                  </Col>
                </Row>
                <Form.Item 
                {...tailFormItemLayout}
                >
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Card>
        </div>
      </div>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...erpActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    EditIssue
  )
);

