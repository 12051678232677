import React, { Component } from "react";
import {
  Form,
  Input,
  Tag,
  Cascader,
  Spin,
  Row,
  Col,
  Descriptions,
  Button,
  Modal,
  Breadcrumb,
  Card,
  message, Upload , Select
} from 'antd';
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as erpActions from "../../../redux/actions/erp-actions";
import ErpSelectSearch from "../../erpnext/select-search";
import { PlusOutlined } from '@ant-design/icons';
import { getBase64 } from "../../../utilities";

const { Option } = Select;
class ViewTaskReview extends Component {
  formRef = React.createRef();
  add_assignee_user_id = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      docinfo: {},
      doctype: "Task",
      auto_repeat: {},
      name: "",
      visible_assignee_popup: false,
      shifts: {},
      assignments: [],
      previewVisible: false,
      previewImage: '',
      previewFileUrl:'',
      previewTitle: '',
      fileList: [],
    };
    
    const { params: { name } } = props.match;

    document.title = "View " + this.state.doctype + ": " + name;

    this.setState({name: name, loading: true});

    this.props.getDoc(
        this.state.doctype,
        name
      ).then(resp => {
        //get auto-repeat
        if(typeof(resp.docs[0].auto_repeat) !== "undefined" && resp.docs[0].auto_repeat){
          this.props.getDoc(
            "Auto Repeat",
            resp.docs[0].auto_repeat
          ).then(resp => {
            this.setState({
              auto_repeat: resp.docs[0]
            });
          })
        }

        //get shifts
        if(resp.docs[0].expected_start_time){
          let fields = ['name', 'start_time', 'end_time']
          this.props.getList({
            doctype: "Shift Type",
            fields: "[" + fields.map(field => "\"`tab" + "Shift Type" + "`.`" + field + "`\"") + "]",
            page_length: "100",
          }).then(shift_resp => {
            this.setState({
              shifts: shift_resp,
            });
          }).catch(ex => {
            
          });
        }


        this.setState({
          loading: false,
          data: resp.docs[0],
          docinfo: resp.docinfo
        });
        if((this.state.docinfo.assignments !== undefined) && this.state.docinfo.assignments.length){

        this.state.docinfo.assignments.forEach( (assignee) => (
          
           // e.preventDefault(); 
            
        this.props.searchLink({
          txt:assignee.owner,
          doctype:'User',
          reference_doctype:'',
          query:'frappe.core.doctype.user.user.user_query',
          filters:'{"user_type":"System User"}'
        }).then(resp => {
          //console.log(resp);
          this.setState({
            assignments: [ ...this.state.assignments, {'owner':assignee.owner, 'name':resp.results[0].description}]     
          })
          console.log(this.state.assignments)
        })

         ));
      }
      
        var newArray = this.state.fileList.slice();  
        resp.docinfo.attachments.forEach(fileObj => {
          newArray.push( {
            uid: fileObj.name,
            name: fileObj.file_name,
            status: 'done',
            url: 'erp/'+fileObj.file_url,
          }); 
        });          
        this.setState({fileList:newArray});

      }).catch(ex => {
        this.setState({ loading: false });
      });
  }

  removeAssignee = (user_id) => {
    Modal.confirm({
      title: 'Confirm',
      //icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to remove the Assignee?',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: () => {
        this.setState({loading: true});
        this.props.assignToRemove({
          'assign_to': user_id,
          'doctype': this.state.doctype,
          'name': this.state.data.name,
        }).then(response => {
          this.setState({loading: false});
          if((typeof(response.error) !== undefined) && (response.error)){
            Modal.error({
              title: 'Error',
              content: "Please try again",
            });
          } else {
            Modal.success({
              title: 'Success',
              content: "Assignee remove successfully",
            });
            setTimeout(() => window.location.reload(false), 2000)
          }

          //setTimeout(() => this.props.history.push('/dashboard/Task'));
        })
      }
    });

    return true

  }

  addAssignee = () => {
    let user_id = this.add_assignee_user_id.input.value;
    if(user_id === "") {
      message.error('Please enter the User ID.', 3.8);
    } else {
      this.setState({loading: true});
      this.props.assignTo({
        'assign_to': user_id,
        'doctype': this.state.doctype,
        'name': this.state.data.name,
      }).then(response => {
        this.setState({loading: false});
        if((typeof(response.error) !== undefined) && (response.error)){
          Modal.error({
            title: 'Error',
            content: "Please try again",
          });
        } else {
          Modal.success({
            title: 'Success',
            content: "Assignee added successfully",
          });
          setTimeout(() => window.location.reload(false), 2000)
        }
      })
    }
  };

 

  handleUploadCancel = () => this.setState({ previewVisible: false });

  handleUploadPreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

 

    this.setState({
      previewImage: file.name.match(/\.(jpg|jpeg|png|gif)$/) ? (file.url || file.preview) : null,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      previewFileUrl:file.url
    });
  };

  showModal = () => {    
    this.setState({ visible: true });
    if(this.formRef.current){
      this.formRef.current.setFieldsValue({
        'taskName':  this.state.data.name,
        //'tableDataIndex': tableDataIndex
      });
    }
  }

  handleSubmit = (values) => {
    this.setState({ visible: false,loading: true });
    console.log(values);    
    this.props.saveField('is_satisfactory', values.review,'Task',this.state.data.name).then(response => {
      var taskDoc = response.data.message;
      this.state.data.is_satisfactory = taskDoc.is_satisfactory;
      this.setState({ loading: false });
    },error => {
      this.setState({ loading: false });
      console.log("error", error);
      Modal.warning({
        title: 'Error',
        content: "Not updated!!",
      });
    }).catch(error => {
      this.setState({ loading: false });
      console.log("||Error: ", error);
      Modal.warning({
        title: 'Error',
        content: "Not updated!",
      });
    });
  }
  
  handleCancel = () => {
    this.setState({ visible: false });
    this.formRef.current.resetFields()
  };
  submitForm = () => {
    this.formRef.current.submit();
};

  render() {
    const { previewVisible, previewImage, fileList, previewTitle,previewFileUrl } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <div id="select-process" className="main-content">
        <div className="create-task-section">
          <Row gutter={24} className="page-header-section">
            <Col className="page-title">
              <Breadcrumb>
                <Breadcrumb.Item><Link to={"/dashboard/TeamReview"}><h1>Team Review</h1></Link></Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>View Task</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            {(this.state.data.name) && (
              <Col>
              <Link to={"/dashboard/Task/edit/" + this.state.data.name}><Button type="primary" size="small">Edit Task</Button></Link>
            </Col>
              )}
          </Row>
          <Card>

          <Spin spinning={this.state.loading}>
          <Descriptions title="Details" labelStyle={{fontWeight: "600"}} contentStyle={{color: "#74767a"}}>
            <Descriptions.Item label="Subject">{this.state.data.subject}</Descriptions.Item>
            <Descriptions.Item label="Status">{this.state.data.status}</Descriptions.Item>
            {/* <Descriptions.Item label="ID">{this.state.data.name}</Descriptions.Item> */}
            <Descriptions.Item label="Activity Type">{this.state.data.type}</Descriptions.Item>
            <Descriptions.Item label="Priority">{this.state.data.priority}</Descriptions.Item>
            <Descriptions.Item label="Expected Start Date">{this.state.data.exp_start_date}</Descriptions.Item>
            <Descriptions.Item label="Expected End Date">{this.state.data.exp_end_date}</Descriptions.Item>
            <Descriptions.Item label="Expected End Time">{this.state.data.expected_start_time}</Descriptions.Item>
            
            <Descriptions.Item label="Task Frequency">{(this.state.auto_repeat.frequency ? this.state.auto_repeat.frequency : "None")}</Descriptions.Item>
            <Descriptions.Item label="Description">{this.state.data.description}</Descriptions.Item>
            <Descriptions.Item label="Satisfactory">{this.state.data.is_satisfactory}</Descriptions.Item>
          </Descriptions>
          <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} className="align-left-5">
                    Attachments
                    <Upload
          disabled={true}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handleUploadPreview}          
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleUploadCancel}
        >
          {
          (previewImage) ? 
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
          :
          <iframe src={previewFileUrl} style={{ width: '100%' }}/>
  }
        </Modal>
                  </Col>
            </Row>  
            <Col xs={24} sm={24} md={24} lg={24} className="align-left-5">
            <Button type="primary" onClick={() => this.showModal()} hidden={this.state.data.status != 'Completed'}>Review</Button> 
              </Col>     
            <Row>
            </Row>   
            </Spin>
            </Card>
        </div>
        <Modal visible={this.state.visible} onOk={this.submitForm} onCancel={this.handleCancel}>
      <Form ref={this.formRef} onFinish={this.handleSubmit}>
        <Form.Item
                  name="review"
                  label="Review"
                >
                  <Select> 
                    <Option value="Yes">Satisfactory</Option>
                    <Option value="No">Not Satisfactory</Option>                          
                  </Select>
        </Form.Item>
        <Form.Item name="taskName" hidden={true}>
            <Input type="text" />
        </Form.Item>       
      </Form>
      </Modal>
      </div>
    );
  }
}


ViewTaskReview.defaultProps = {
  showActionTab: true
};

function mapStateToProps(state) {
  return {
    ...state.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...erpActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ViewTaskReview
  )
);