import {
    Types
} from "../constants/auth-types";
import Axios from "axios";
import Cookies from 'js-cookie';

export function login(data) {
    //localStorage.setItem("user_id", data.name);
    localStorage.setItem("emp_id", data.name);
    localStorage.setItem("employee_name", data.full_name);
    localStorage.setItem("employee_first_name", data.first_name);
    //localStorage.setItem("date_of_birth", data.date_of_birth);
    localStorage.setItem("email", data.email);
    localStorage.setItem("user_image", data.user_image);

    var pdata =  {
        //"user_id":data.name,
        "emp_id":data.name,
        'employee_name':data.full_name,
        'first_name':data.first_name,
        //'date_of_birth':data.date_of_birth,
        'email':data.email,
        'user_image':data.user_image
    };

    return function (dispatch, getState) {
        dispatch({
            type: Types.LOGIN,
            payload: pdata
        });
    };
}

export function logout() {
    localStorage.clear();
    Cookies.remove('sid');
    return function (dispatch, getState) {
        dispatch({
            type: Types.LOGOUT,
            payload: {}
        });
    };
}
