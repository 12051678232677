import Axios from "axios";
import qs from "querystring";
//import Axios from "../../services/axios-service";

var API_URL = process.env.REACT_APP_ERPNEXT_URL;
var PUBLIC_URL = process.env.PUBLIC_URL;
var FRAPPE_TOKEN = (localStorage.getItem('frapee-token') === null) ? "" : localStorage.getItem('frapee-token')

const getList = (doc, data, allow_guest = false) => {
    return Axios.get(
        API_URL + "/api/resource/" + doc,
        {
            params: {
                "fields": '["name", "country", "creation", "modified", "", "", ""]',
            },
            withCredentials: true,
        },
        undefined);
};

const insert = (doc, id) => {
    return Axios.get(
        this.url + "/api/" + doc,
        undefined,
        undefined);
};

const getDoc = (doc, name, allow_guest = false) => {  
  return new Promise(function(resolve, reject) {
    Axios.get(
      API_URL + "/api/method/frappe.desk.form.load.getdoc" + (allow_guest ? "_public" : "") + "?doctype=" + encodeURIComponent(doc) + "&name=" + encodeURIComponent(name),
      { withCredentials: true, }, 
      undefined).then(res => {
        resolve(res);
      }).catch((e) => {
        resolve(e.response);
      });
    }
  );
};

const generateToken = (user_id) => {  
  return Axios({
    method: 'GET',
    url: API_URL + "/api/resource/User/" + user_id,
    withCredentials: true,
  })
  .then(function (response) {
    const api_key = response.data.data.api_key
    if (1){
      return Axios({
        method: 'GET',
        url: API_URL + "/api/method/frappe.core.doctype.user.user.generate_keys_public?user=" + user_id,
        withCredentials: true,
      })
      .then(function (response) {
        const api_secret = response.data.message.api_secret
        const token = api_key + api_secret;
        return token;
      })      
    }
    return false;
  });
};

const saveDoc = (doc, action) => {
  return new Promise(function(resolve, reject) {
    Axios({
      method: 'POST',
      url: API_URL + "/api/method/frappe.desk.form.save.savedocs",
      data: qs.stringify({'doc' : JSON.stringify(doc), 'action' : action}),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "Accept": "application/json",
        'X-Frappe-CSRF-Token': FRAPPE_TOKEN
      },
      withCredentials: true,
    }).then(res => {
      resolve(res);
    }).catch((e) => {
      resolve(e.response);
    });
  });
}

const makeMappedDoc = (params) => {
  return Axios({
    method: 'POST',
    url: API_URL + "/api/method/frappe.model.mapper.make_mapped_doc",
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      "Accept": "application/json",
      'X-Frappe-CSRF-Token': FRAPPE_TOKEN
    },
    withCredentials: true,
  });
}

const assignTo = (params) => {
  return Axios({
    method: 'POST',
    url: API_URL + "/api/method/frappe.desk.form.assign_to.add",
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      "Accept": "application/json",
      'X-Frappe-CSRF-Token': FRAPPE_TOKEN
    },
    withCredentials: true,
  });
}

const assignToRemove = (params) => {
  return Axios({
    method: 'POST',
    url: API_URL + "/api/method/frappe.desk.form.assign_to.remove",
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      "Accept": "application/json",
      'X-Frappe-CSRF-Token': FRAPPE_TOKEN
    },
    withCredentials: true,
  });
}

const uploadFile = (options) => {
  var formData = new FormData();
  formData.append("docname", options.docname);
  formData.append("doctype", options.doctype);
  formData.append("folder", "Home");
  formData.append("is_private", 0);
  formData.append('file', options.file);
  return new Promise(async (resolve, reject) => {
    try{
      Axios({
        method: 'POST',
        url: API_URL + '/api/method/upload_file',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
            "Accept": "application/json",
            'X-Frappe-CSRF-Token': FRAPPE_TOKEN
        },
        withCredentials: true,
      })
      .then(function (response) {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    } catch (error) {
      reject(error);
    }
  });

}

const reportView = (params, allow_guest = false) => {
    return new Promise(function(resolve, reject) {
      Axios.get(
        API_URL + "/api/method/frappe.desk.reportview.get" + (allow_guest ? "_public" : ""),
        {
            params: params,
            withCredentials: true,
        },
        undefined).then(res => {
          resolve(res);
        }).catch((e) => {
          resolve(e.response);
        });
      }
    );
};

const getLines = (chart_name) => {  
  return new Promise(function(resolve, reject) {
    Axios.get(
      API_URL + "/api/method/erpnext.projects.dashboard_chart_source.task_count.task_count.get" + "?chart_name=" + encodeURIComponent(chart_name) ,
      { withCredentials: true, }, 
      undefined).then(res => {
        resolve(res);
      }).catch((e) => {
        resolve(e.response);
      });
    }
  );
};


const searchLink = (params) => {
  return new Promise(function(resolve, reject) {
    Axios.post(
      API_URL + "/api/method/frappe.desk.search.search_link",
      qs.stringify(params), 
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Frappe-CSRF-Token': FRAPPE_TOKEN
        },
        withCredentials: true,
      }).then(res => {
        resolve(res);
      }).catch((e) => {
        resolve(e.response);
      });
    }
  );

};

const getPOSItems = (params) => {
  return Axios.post(
      API_URL + "/api/method/erpnext.selling.page.point_of_sale.point_of_sale.get_items",
      qs.stringify(params), 
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Frappe-CSRF-Token': FRAPPE_TOKEN
        },
        withCredentials: true,
      });
};


const stock_getItemDetails = (doc, args) => {

  var formData = new FormData();
  formData.append("doc", JSON.stringify(doc));
  formData.append("args", JSON.stringify(args));
  
  return Axios({
    method: 'POST',
    url: API_URL + "/api/method/erpnext.stock.get_item_details.get_item_details",
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      "Accept": "application/json",
      'X-Frappe-CSRF-Token': FRAPPE_TOKEN
    },
    withCredentials: true,
  });

};

const getPartyDetails = (params) => {

  return Axios.post(
    API_URL + "/api/method/erpnext.selling.page.point_of_sale.point_of_sale.get_items",
    qs.stringify(params), 
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Frappe-CSRF-Token': FRAPPE_TOKEN
      },
      withCredentials: true,
    });

};

const applyPricingRule = (doc, args) => {

  var formData = new FormData();
  formData.append("doc", JSON.stringify(doc));
  formData.append("args", JSON.stringify(args));
  
  return Axios({
    method: 'POST',
    url: API_URL + "/api/method/erpnext.accounts.doctype.pricing_rule.pricing_rule.apply_pricing_rule",
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      "Accept": "application/json",
      'X-Frappe-CSRF-Token': FRAPPE_TOKEN
    },
    withCredentials: true,
  });

};

const changeTitle = (params) => {

  return Axios.post(
    API_URL + "/api/method/frappe.model.rename_doc.update_document_title",
    qs.stringify(params), 
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Frappe-CSRF-Token': FRAPPE_TOKEN
      },
      withCredentials: true,
    });

};


const logout = () => {
  return Axios({
    method: 'POST',
    url: API_URL + "/api/method/logout",
    headers: {
      "Accept": "application/json",
    },
    withCredentials: true,
  });
}

const makeAutoRepeat = (params) => {

  return Axios.post(
    API_URL + "/api/method/frappe.automation.doctype.auto_repeat.auto_repeat.make_auto_repeat",
    qs.stringify(params), 
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Frappe-CSRF-Token': FRAPPE_TOKEN
      },
      withCredentials: true,
    });

};

const saveField = (fieldname,fieldvalue, doctype,docname) => {
  return new Promise(function(resolve, reject) {
    Axios({
      method: 'POST',
      url: API_URL + "/api/method/frappe.client.set_value",
      data: qs.stringify({'doctype' : doctype,"name": docname,
      "fieldname": fieldname,
      "value": fieldvalue			
      			
			}),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "Accept": "application/json",
        'X-Frappe-CSRF-Token': FRAPPE_TOKEN
      },
      withCredentials: true,
    }).then(res => {
      resolve(res);
    }).catch((e) => {
      resolve(e.response);
    });
  });
}

export default {
    getList,
    insert,
    getDoc,
    saveDoc,
    searchLink,
    makeMappedDoc,
    reportView,
    getLines,
    uploadFile,
    generateToken,
    getPOSItems,
    stock_getItemDetails,
    getPartyDetails,
    applyPricingRule,
    changeTitle,
    //logout,
    assignTo,
    assignToRemove,
    makeAutoRepeat,
    saveField
};






/*
Axios({
    method: 'GET',
    url: "http://178.128.145.124" + "/api/resource/" + "Company",
    data: {
      
    },
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
  .then(function (response) {
    
    console.log(response);
  })
  .catch(function (error) {//
   
    console.log(error);
  });


function Client(settings) {

    // Initialize the settings
    this.url = settings.url;
  
  }
  
  Client.prototype.insert = function(doc) {
    return fetch(this.url + "/api/resource/" + doc.doctype, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ data: JSON.stringify(doc) })
    });
  };
  
  Client.prototype.delete = function(doctype, name) {
    return fetch(this.url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "cmd": "frappe.client.delete",
        "doctype": doctype,
        "name": name
      })
    });
  };
  
  Client.prototype.cancel = function(doctype, name) {
    return fetch(this.url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "cmd": "frappe.client.cancel",
        "doctype": doctype,
        "name": name
      })
    });
  };
  
  Client.prototype.submit = function(doc) {
    return fetch(this.url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "cmd": "frappe.client.submit",
        "doc": JSON.stringify(doc)
      })
    }).then((res) => res.json());
  };
  
  Client.prototype.getList = function(doctype) {
    return fetch(this.url + "/api/resource/" + doctype, { method: "GET" }).then((res) => res.json());
  };
  
  Client.prototype.getDoc = function(doctype, name) {
    return fetch(this.url + "/api/resource/" + doctype + "/" + name, { method: "GET" }).then((res) => res.json());
  };
  
  Client.prototype.getApi = function(method) {
    return fetch(this.url + "/api/method/" + method, { method: "GET" }).then((res) => res.json());
  };
  
  Client.prototype.postApi = function(method, data) {
    return fetch(this.url + "/api/method/" + method, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ data })
    });
  };
  
  module.exports = {
  
    // Client object
    Client: Client,
  
    // Create client based on the settings given
    createClient: function(settings) {
  
      // Error for missing settings
      ["url", "username", "password"].forEach(function(prop) {
        if (!settings[prop]) {
          throw new Error("Missing required settings: " + prop);
        }
      });
  
      // Login to the server
      return fetch(settings.url, {
        method: "POST",
  
        credentials: 'include',
        crossDomain: true,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          "cmd": "login",
          "usr": settings.username,
          "pwd": settings.password
        })
      }).then((res) => {
  
        // If not success
        if (res.status !== 200) {
          throw new Error("Unable to login. (Error Code: " + res.status + ")");
        }
  
        // Initialize the Client object
        this.Client = new Client(settings);
  
        return res;
  
      });
  
    },
  
  };
  */