import React, { Component } from "react";
import { Route } from "react-router-dom";
import dashboardLanding from "../components/dashboard/dashboard-landing/index";

import issueListing from "../components/dashboard/issue/index";
import editIssue from "../components/dashboard/issue/add";
import viewIssue from "../components/dashboard/issue/view";

import Dashboard from "../components/dashboard/task/index.jsx";
import viewTask from "../components/dashboard/task/view";

import editTask from "../components/dashboard/task/edit";
import teamReview from "../components/dashboard/team-review/index";
import viewTeamReview from "../components/dashboard/team-review/view";


const routes = [
    {
      path: "",
      component: dashboardLanding,
      exact: true
    },
    {
      path: "Issue",
      component: issueListing,
      exact: true
    },
    {
      path: "Issue/new",
      component: editIssue,
      exact: true
    },
    {
      path: "Issue/edit/:name",
      component: editIssue,
      exact: true
    },
     {
       path: "Issue/view/:name",
       component: viewIssue,
       exact: true
     },

    {
      path: "Task",
      component: Dashboard,
      exact: true
    },
    {
      path: "Task/new",
      component: editTask,
      exact: true
    },
     {
       path: "Task/view/:name",
       component: viewTask,
       exact: true
     },
    {
      path: "Task/edit/:name",
      component: editTask,
      exact: true
    },
    {
      path: "TeamReview",
      component: teamReview,
      exact: true
    },
    {
      path: "TeamReview/view/:name",
      component: viewTeamReview,
      exact: true
    },

  ];
  
  class DashboardRouter extends Component {
    render() {
      const { url, style } = this.props;
      return (
        <div style={style}>
          {routes.map(singleRoute => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact === false ? false : true}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </div>
      );
    }
  }
  
  export { routes };
  export default DashboardRouter;
  