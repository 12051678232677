import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import UserRoles from "./user-roles";


const  PrivateRoute =  ({ component: Component, roles,status,isLoggedIn,...rest }) => {
    const empCurrent =localStorage.getItem("emp_id");
    const location = useLocation();
    let pathname = location.pathname;
    //console.log(pathname)
    /* let loggedInUserroleURL = "";
    if(currentUser)
        loggedInUserroleURL = UserRoles.types[currentUser.role].url;
    else
        loggedInUserroleURL ="/"; */

   return( <Route {...rest} render={({props }) => {
        if(empCurrent){
            if(pathname == "/" || pathname == "/login"){
                return (<Redirect to={{ pathname: "/dashboard" }} />)    
            }else{
            
                return (<Component {...props} />)
            }
            
        }else{
            return (<Redirect to={{ pathname: "/login" }} />)
        }
        //return (<Component {...props} />)
    }} />)
}
export default  PrivateRoute
