import API from "../api/frapee-client";
import Axios from "axios";
import moment from 'moment-timezone';

export function getList(params, allow_guest = false) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.reportView(params, allow_guest);
            return mergeKeyValue(resp);
        } catch (e) {
            return {"error" : true};
        }
    };
}
export function getCount(params, allow_guest = false) {
    return async function(dispatch, getState) {
        params.fields = "[\"count(`tab" + params.doctype + "`.`name`) AS total_count\"]";
        params.filters = (params.filters !== undefined) ? params.filters : "[]";
        let resp = await API.reportView(params, allow_guest);
        if(resp.status === 200){
            if (((resp.data !== undefined)) && (resp.data.message.values[0] !== undefined)) {
                return mergeKeyValue(resp)[0];
            }
        } else {
            if(resp.status === 403) {
                handle403()
            }
        }
        return false;
    };
}
export function getDoc(docType, name, allow_guest = false) {
    return async function(dispatch, getState) {
        let resp = await API.getDoc(docType, name, allow_guest);
        if(resp.status === 200){
            return resp.data;
        } else {
            if(resp.status === 403) {
                handle403()
            }
        }
        return false;
    };
}
export function saveDoc(doc, action) {
    return async function(dispatch, getState) {
        let resp = await API.saveDoc(doc, action);
        if(resp.status === 200){
            return resp;
        } else {
            if(resp.status === 403) {
                handle403()
            } else {
                return resp
            }
        }
        return false;
    };
}
export function searchLink(params) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.searchLink(params);
            return resp.data;
        } catch (e) {
            return {"error" : true};
        }
    };
}
export function getLines(chart_name) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.getLines(chart_name);
            return resp.data;
        } catch (e) {
            console.log(e)
            return {"error" : true};
        }
    };
}
export function generateToken(user_id) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.generateToken(user_id);
            return resp;
        } catch (e) {
            return {"error" : true};
        }
    };
}
export function makeMappedDoc(params) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.makeMappedDoc(params);
            return resp.data;
        } catch (e) {
            return {"error" : true};
        }
    };
}
export function assignTo(params) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.assignTo(params);
            return resp.data;
        } catch (e) {
            return {"error" : true};
        }
    };
}

export function assignToRemove(params) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.assignToRemove(params);
            return resp.data;
        } catch (e) {
            return {"error" : true};
        }
    };
}

//TASK FUNCTIONS
export function checkInProgressTask(){
    let or_filters = "[[\"Task\",\"_assign\",\"like\",\"%" + getLocalStorage("user_id") + "%\"],[\"Task\",\"owner\",\"=\",\"" + getLocalStorage("user_id") + "\"]]";
    return async function(dispatch, getState) {
        getCount({
            doctype: "Task",
            filters: '[["Task","is_group","=",0],["Task","status","=","In Progress"]]',
            or_filters: or_filters
        }).then(resp => { 
            return (resp.total_count);
        }).catch(ex => {

        });
    }.bind(getCount);
}
export function uploadIcon(options) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.uploadFile(options);
            return resp;
        } catch (error) {
            throw error;
        }
    }    
}

export function uploadFile(options) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.uploadFile(options);
            return resp;
        } catch (e) {
            return {"error" : true};
        }
    }    
}

export function getPOSItems(params) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.getPOSItems(params);
            return resp.data;
        } catch (e) {
            return {"error" : true};
        }
    };
}

export function stock_getItemDetails(doc, args) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.stock_getItemDetails(doc, args);
            return resp.data;
        } catch (e) {
            return {"error" : true};
        }
    };
}

export function getPartyDetails(params) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.getPartyDetails(params);
            return resp.data;
        } catch (e) {
            return {"error" : true};
        }
    };
}

export function applyPricingRule(doc, args) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.applyPricingRule(doc, args);
            return resp.data;
        } catch (e) {
            return {"error" : true};
        }
    };
}

export function changeTitle(doc, newTitle, titleField = 'title') {
    return async function(dispatch, getState) {
        try {
            let resp = await API.changeTitle({
                'doctype': doc.doctype,
                'docname': doc.name,
                'title_field': titleField,
                'old_title': doc.title,
                'new_title': newTitle,
                //'new_name': CRM-OPP-2020-00005,
                'merge': 0,
            });
            return resp.data;
        } catch (e) {
            return {"error" : true};
        }
    };
}

export function getLocalStorage(key) {
    return function(dispatch) {
        if (localStorage.getItem(key) === null) {
            //... return to Login page
            return null;
        }

        return localStorage.getItem(key)
    }
}

export function getCurrentERPTime() {
    return function(dispatch) {
        return moment.tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss')
    }
}

export function convertToCurrentTimezone(datetime) {
    return function(dispatch) {
        var a = moment.tz(datetime, 'America/Los_Angeles');

        if(moment.tz.guess()){
            return a.tz(moment.tz.guess()).format('MM-DD-YYYY HH:mm:ss')
        }

        return datetime;
    }
}

export function covertToHoursMinutes(totalHours) {
    return function(dispatch) {
        var hours = Math.floor(totalHours);          
        var minutes = ('0'+((totalHours % 1)*60).toFixed()).slice(-2);
        return hours + ":" + minutes;
    }
}

function mergeKeyValue(resp) {
    let data = [];
    if(resp.data.message.values.length){
        resp.data.message.values.forEach((row, index) => {
            data[index] = {};
            row.forEach((val, i) => {
                data[index][resp.data.message.keys[i]] = val;
            })
        });
    }
    return data;
}

function handle403() {
    window.location.href = '/login'
}

export function  getNameFromEmail (email){
    return function(dispatch) {
        const allEmployeeData = JSON.parse(localStorage.getItem('allEmployeeData'));
        if(allEmployeeData){
            const emp = allEmployeeData.find(employee=>{
                return employee.prefered_email == email
            });
            return (emp) ? emp.employee_name : email
        }
        return email;
    }
}

export function makeAutoRepeat(params) {
    return async function(dispatch, getState) {
        try {
            let resp = await API.makeAutoRepeat(params);
            return resp.data;
        } catch (e) {
            return {"error" : true};
        }
    };
}
export function saveField(fname, fvalue,doctype,docname) {
    return async function(dispatch, getState) {
        let resp = await API.saveField(fname, fvalue,doctype,docname);
        if(resp.status === 200){
            return resp;
        } else {
            if(resp.status === 403) {
                handle403()
            } else {
                return resp
            }
        }
        return false;
    };
}