import React, { Component } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  PageHeader,
  Spin,
  Badge,
  Avatar,
  Dropdown,
  Image,
  notification
} from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CaretDownFilled,
  SettingOutlined,
  HomeOutlined,
  DeploymentUnitOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sidebarAction from "../../redux/actions/sidebar-actions";
import * as erpActions from "../../redux/actions/erp-actions";
import * as authActions from  "../../redux/actions/auth-actions";
import { Layout,Menu,Modal } from 'antd';

import ErpSelectSearch from "../erpnext/select-search";
import TextArea from "antd/lib/input/TextArea";
import Logo from "../../images/logo.png";
import Profile from "../../images/profile-img.png";
import BellIcon from "../../images/notifications.png";
import DashboardRouter from "../../routes/dashboard-router.js";

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
/*const profilemenu = (
  <Menu className="profile-submenu">
    <Menu.Item key="0">
      <a href="/profile">User Profile</a>
    </Menu.Item>
    <Menu.Item key="1">
      <a href="#">Logout</a>
    </Menu.Item>
  </Menu>
);*/

class UserProfile extends Component {

 

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handelLogout = () => {
    this.props.logout();
    this.props.history.push('/')
  }

  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      doctype: "User",
      data: "",
      new: false,
      loading: true,
      collapsed: false,
    };
  }

  async componentDidMount() {
    let data = "";

    const   name  = localStorage.name;
    document.title = "Edit " + this.state.doctype + ": " + name;
    
    if(name) {
      await this.props.getDoc(
        this.state.doctype,
        name
      ).then(resp => {
        data = resp.docs[0]
      }).catch(ex => {
        console.log(ex);
      });
    } else {
      
      data = {
        ...this.state.data,
        ...{
          "docstatus": 0,
          "doctype": this.state.doctype,
          "name": "New " + this.state.doctype,
          "__islocal":1,
          "__unsaved":1,
        }
      };

      if(typeof this.props.location.state !== 'undefined' ){
        data = {
          ...data,
          ...this.props.location.state.data
        }
      }
    }

    this.formRef.current.setFieldsValue(data);

    this.setState({
      data: data,
      loading: false,
      new: ((name) ? false : true)
    });

  }

  onFinish = values => {

    this.setState({ loading: true });

    let newState = {...this.state.data, ...values};

    this.props.saveDoc(newState, "Save").then(response => {
      if(response.status === 200) {
        notification['success']({
          message: 'Data Saved',
          description: '',
        });
        setTimeout(() => this.props.history.push("/profile"), 1500);
      } else {
        this.setState({ loading: false });
        if( (response.data !== undefined) && (response.data._server_messages !== undefined) ) {
          var message = JSON.parse(JSON.parse(response.data._server_messages)[0]).message;
        } else {
          var message = "Unexpected error occured";
        }
        Modal.warning({
          title: 'Error',
          content: message,
          //onOk: function(){ props.history.push(("/dashboard/" + this.state.doctype); },
        });
      }
    },error => {
      this.setState({ loading: false });
      console.log("error", error);
      Modal.warning({
        title: 'Error',
        content: "Not Saved!!",
      });
    }).catch(error => {
      this.setState({ loading: false });
      console.log("||Error: ", error);
      Modal.warning({
        title: 'Error',
        content: "Not Saved!",
      });
    });
  };

  onFinishFailed = ({ errorFields }) => {
    this.formRef.current.scrollToField(errorFields[0].name);
  };
  

  render() {
    const { url } = this.props.match;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const routes = [
      {
        path: '/dashboard',
        breadcrumbName: 'Home',
      },      
      {
        path: '/profile',
        breadcrumbName: (this.state.new) ? "Add " + this.state.doctype : 'Edit ' + this.state.data.first_name,
      },
    ];
    const { user } = this.props;
    

    const profilemenu = (
      <Menu className="profile-submenu">
        <Menu.Item key="0">
          <a href="/profile">User Profile</a>
        </Menu.Item>
        <Menu.Item key="1">
          <a href="#" onClick={this.handelLogout}>Logout</a>
        </Menu.Item>
      </Menu>
    );
    return (
      <div className="wrap dashboard">
        <Layout style={{ minHeight: '100vh' }}>
          <Sider 
            trigger={null} collapsible
            style={{flex: '0 0 250px', maxWidth: '250px', minWidth: '250px', width: '250px'}}
            breakpoint="md"
            collapsedWidth="0"
            onBreakpoint={broken => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            collapsed={this.state.collapsed} onCollapse={this.onCollapse} 
          >
            <div className="logo" >
                <Link to="/dashboard"><img src={Logo} alt="logo" style={{width: '130px'}}/></Link>
            </div>
            <Menu 
              theme="light" 
              defaultSelectedKeys={['/']} 
              selectedKeys={[window.location.pathname]} 
              mode="inline"
            >
              <Menu.Item key="/dashboard">
                <Link to="/dashboard">Home</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/Task">
                <Link to="/dashboard/Task">Task</Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/Issue">
                <Link to="/dashboard/Issue">Ticket</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background">
              {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: this.toggle,
              })}
              <Menu style={{float: 'right'}} theme="light" mode="horizontal" defaultSelectedKeys={['2']} className="" >
                <Menu.Item className="bell-icon">
                  <span className="avatar-item">
                    <Badge count={1}>
                      <Avatar src={<Image src={BellIcon} alt="Bell Icon" />} />
                    </Badge>
                  </span>
                </Menu.Item>
                  <Dropdown overlay={profilemenu} trigger={['click']} className="user-profile">
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {(user.user_image) ? <Avatar src={(user.user_image.indexOf("http") === 0) ? user.user_image : "/erp/" + user.user_image} /> : <Avatar>{user.employee_name.match(/\b(\w)/g).join('').toUpperCase()}</Avatar>}                                                                                                                                                                                                                                                                                                                                                                                              
                      <CaretDownFilled />
                    </a>
                  </Dropdown>
              </Menu>
            </Header>
            <Content style={{ margin: '16px' }}>
              
              <Spin spinning={this.state.loading}>
        <PageHeader
          style={{
            //border: '1px solid rgb(235, 237, 240)',
          }}
          title = {(this.state.new) ? "Add " + this.state.doctype : this.state.data.name}
          breadcrumb={{ 'routes': routes, 'itemRender': (route, params, routes, paths) => (routes.indexOf(route) === routes.length - 1) ? (<span>{route.breadcrumbName}</span>) : (<Link to={route.path}>{route.breadcrumbName}</Link>) }}
          //subTitle="This is a subtitle"
          extra={[
            
          ]}
        >

        </PageHeader>
        <Form ref={this.formRef} {...formItemLayout} onFinish={this.onFinish} onFinishFailed={this.onFinishFailed} initialValues={{layout: tailFormItemLayout, name: this.state.data.name}}>
          <Form.Item label="First Name" name="first_name" rules={[{ required: true }]} >
            <Input />
          </Form.Item>
          <Form.Item label="Last Name" name="last_name" rules={[{ required: false }]} >
            <Input />
          </Form.Item>
          <Form.Item  label="Email" name="email" rules={[{ required: true }]} >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
                        name="gender"
                        label="Gender"
                      >
                        <Select allowClear>
                          <Option value="Male">Male</Option>
                          <Option value="Female">Female</Option>                          
                        </Select>
          </Form.Item>
          <Form.Item
                        name="mobile_no"
                        label="Mobile"
                      >
                        <Input />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Spin>
            </Content>
          </Layout>
        </Layout>
      </div>
      
    );
  }
}
UserProfile.defaultProps = {
  doctype: "User"
}
function mapStateToProps(state) {
  const {  auth } = state;
    const { user } = auth;
    
  return {
    user,
    ...state.sidebar
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...sidebarAction, ...erpActions,...authActions  }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    UserProfile
  )
);