//import Home from "../components/home/home.jsx";
import Login from "../components/log-in/login";
import ForgotPassword from "../components/forgot-password/forgot-password";
import AdminLayout from "../components/dashboard/index";
import dashboard from "../components/dashboard/dashboard";
import profile from "../components/dashboard/profile";
export default {
  routes: [
    {
      path: "/",
      component: Login,
      exact: true
    },
    {
      path: "/login",
      component: Login,
      exact: true
    },
    {
      path: "/forgot-password",
      component: ForgotPassword,
      exact: true
    },
    {
      path: "/admin/",
      component: AdminLayout,
      exact: true
    },
  ],
  redirects: [
    {
      from: "/404",
      to: "/admin-dash",
      status: 301
    }
  ],
  private: [
    {
      path: "/dashboard",
      component: dashboard,
      exact: false,
      //roles: ["resturant"],
      status: 200
    },
    {
      path: "/profile",
      component: profile,
      exact: false,
      //roles: ["resturant"],
      status: 200
    }
  ]
};
