import {
    combineReducers
} from 'redux';

import sidebarReducer from "./sidebar-reducer";
import authReducer from "./auth-reducer";
import barcodeListReducer from './barcode-list-reducer';

const reducers = combineReducers({
    sidebar: sidebarReducer,
    auth : authReducer,
    barcodeList : barcodeListReducer
});

export default reducers;