import React, { Component } from "react";
import { Typography, Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
class Footer extends Component {
  render() {
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Typography.Title level={4}>
             Footer
            </Typography.Title>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(
  connect(null, null, null, { forwardRef: true })(
    Footer
  )
);
