import React from 'react';
import ReactDOM from 'react-dom';
import { hydrate } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../src/shared/app/redux/store.js";
import App from '../src/shared/app/App';

hydrate(
    <Provider store={store}>
      <Router basename={process.env.PUBLIC_URL}>
        <App />
      </Router>
    </Provider>,
  
    document.getElementById("root")
  );
