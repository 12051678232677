import React, { Component,useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Table, Card, Typography, Divider, PageHeader, List, Avatar } from "antd";

import CompletedImg from "../../../images/completed.jpg";
import InCompletedImg from "../../../images/incompleted.jpg";
import InProgressImg from "../../../images/inprogress.jpg";
import {ReactComponent as EditIcon} from "../../../images/edit.svg";
import {ReactComponent as ViewIcon} from "../../../images/eye.svg";
import {ReactComponent as ClockIcon} from "../../../images/clock-icon.svg";
import * as erpActions from "../../../redux/actions/erp-actions";
import { bindActionCreators } from "redux";


import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import task from "../task";
import ReactTimeAgo from "react-time-ago/commonjs/ReactTimeAgo";
import { TinyLine } from '@ant-design/charts';

JavascriptTimeAgo.locale(en)

const { Title } = Typography;

const taskdata = [
  {
    icon: 'F',
    title: 'Floor Cleaning',
    datadesc: '5 days delays',
  },
  {
    icon: 'F',
    title: 'Fridge Loading',
    datadesc: '5 days delays',
  },
  {
    icon: 'W',
    title: 'Wardrobe Cleaning',
    datadesc: '5 days delays',
  },
  {
    icon: 'C',
    title: 'Counter Cleaning',
    datadesc: '5 days delays',
  },
];

const userdata = [
  {
    title: 'John Doe',
    desig: 'Account Manager',
  },
  {
    title: 'Alex Smith',
    desig: 'Counter Manager',
  },
  {
    title: 'Petric Smith',
    desig: 'Service & Operation Manager',
  },
  {
    title: 'Nicky Hunt',
    desig: 'Cleaner',
  },
];

class dashboardLanding extends Component {
  
  state = {
    loading: false,    
    task: {
      loading: false,
      doctype: "Task",
      fields: ["subject", "name", "type", "owner", "_assign", "creation", "modified", "modified_by", "status", "docstatus", "exp_end_date"],
      data: [],
      pagination: {
        pageSize: 5,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => <span>Showing {range[0]}-{range[1]} of {total}</span>
      },
      sorter: {
        field: "modified",
        order: "descend"
      },
      filters: [],
    },
    user: {
      loading: false,
      doctype: "User",
      fields: ["full_name", "name", "creation", "modified", "user_image"],
      data: [],
      pagination: {
        pageSize: 5,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => <span>Showing {range[0]}-{range[1]} of {total}</span>
      },
      sorter: {
        field: "modified",
        order: "descend"
      },
      filters: '[["Has Role","role","in",["Employee","System Manager"]]]',
    },
    taskCount: {completed:0,inprogress:0,incompleted:0},
    taskLines: {completed:[0,0,0,0,0,0,0],inprogress:[0,0,0,0,0,0,0],incompleted:[0,0,0,0,0,0,0]}
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.calculateCounts();
    this.setTaskLines();
    this.handleTableChangeTask(this.state.task.pagination, {}, this.state.task.sorter);
    this.handleTableChangeUser(this.state.task.pagination, {}, this.state.task.sorter);

  }

  calculateCounts = () =>{    
    this.setState({taskCount: {completed:0,inprogress:0,incompleted:0}, loading: true});
    
      let date = new Date();
      const map = {
          mm: (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1),
          dd: date.getDate(),
          //yy: date.getFullYear().toString().slice(-2),
          yyyy: date.getFullYear()
      }
  
      let todayDateF = 'yyyy-mm-dd'.replace(/mm|dd|yyyy/gi, matched => map[matched]);
  
    this.props.getCount({
      doctype: "Task",
      filters: '[["Task","status","in",["Open","Working","Pending Review",null]],["Task","modified",">=","'+todayDateF+'"]]'
    }).then(resp => {
      console.log(resp)
         
      this.setState({taskCount: {...this.state.taskCount, inprogress: resp.total_count}});
      this.props.getCount({
        doctype: "Task",
        filters: '[["Task","status","in",["Completed",null]],["Task","modified",">=","'+todayDateF+'"]]'
      }).then(resp => {
        console.log(resp)
           
        this.setState({taskCount: {...this.state.taskCount,completed: resp.total_count}});
        this.props.getCount({
          doctype: "Task",
          filters: '[["Task","status","in",["Overdue",null]],["Task","modified",">=","'+todayDateF+'"]]'
        }).then(resp => {
          console.log(resp)
             
          this.setState({taskCount: {...this.state.taskCount,incompleted: resp.total_count}});
        })
      })
    }).catch(ex => {
      this.setState({ loading: false});
    });
  };
  setTaskLines = () => {
    //this.setState({taskLines: {completed:[],inprogress:[],incompleted:[]}, loading: true});
    this.props.getLines( "Completed" ).then(resp => {
      console.log(resp)
      if(resp.message){
        this.setState({taskLines: {...this.state.taskLines, completed: resp.message.datasets[0].values}});
      }
      this.props.getLines( "InProgress").then(resp => {
        console.log(resp)
        if(resp.message){
          this.setState({taskLines: {...this.state.taskLines,inprogress: resp.message.datasets[0].values}});
        }
        this.props.getLines( "InCompleted").then(resp => {
          console.log(resp)
          if(resp.message){
            this.setState({taskLines: {...this.state.taskLines, incompleted: resp.message.datasets[0].values}});
          }
        })
      })
    }).catch(ex => {
      console.log(ex);
      this.setState({ loading: false});
    });

  };
  


  handleTableChangeTask = (pagination, filters, sorter) => {
    this.setState({task: {...this.state.task, pagination: pagination, sorter: sorter, loading: true}});

    this.props.getCount({
      doctype: "Task",
    }).then(resp => {
      const pagination = { ...this.state.task.pagination };
      pagination.total = resp.total_count;
      this.setState({task: {...this.state.task, pagination: pagination}});

      this.props.getList({
        doctype: "Task",
        fields: "[" + this.state.task.fields.map(field => "\"`tab" + "Task" + "`.`" + field + "`\"") + "]",
        filters: this.state.task.filters,
        order_by: (sorter.field) ? "`tab" + "Task" + "`.`" + sorter.field + "` " + ((sorter.order === "ascend") ? "asc" : "desc") : "",
        start: pagination.pageSize * (pagination.current - 1),
        page_length: pagination.pageSize,
        with_comment_count: true,
      }).then(resp => {
        this.setState({task: {...this.state.task, loading: false, data: resp}});
      }).catch(ex => {
        this.setState({task: {...this.state.task, loading: false}});
        
      });
    }).catch(ex => {
      this.setState({task: {...this.state.task, loading: false}});
    });
  };

  handleTableChangeUser = (pagination, filters, sorter) => {
    this.setState({user: {...this.state.user, pagination: pagination, sorter: sorter, loading: true}});

    this.props.getCount({
      doctype: "User",
    }).then(resp => {
      const pagination = { ...this.state.user.pagination };
      pagination.total = resp.total_count;
      this.setState({user: {...this.state.user, pagination: pagination}});

      this.props.getList({
        doctype: "User",
        fields: "[" + this.state.user.fields.map(field => "\"`tab" + "User" + "`.`" + field + "`\"") + "]",
        filters: this.state.user.filters,
        order_by: (sorter.field) ? "`tab" + "User" + "`.`" + sorter.field + "` " + ((sorter.order === "ascend") ? "asc" : "desc") : "",
        start: pagination.pageSize * (pagination.current - 1),
        page_length: pagination.pageSize,
        with_comment_count: true,
      }).then(resp => {
        this.setState({user: {...this.state.user, loading: false, data: resp}});
      }).catch(ex => {
        this.setState({user: {...this.state.user, loading: false}});
        
      });
    }).catch(ex => {
      this.setState({user: {...this.state.user, loading: false}});
    });
  };

  render() {

    return (
      <div>
        <h1>Dashboard</h1>
        <Card className="sales-status">
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={8} md={8} lg={8}>
              <div className="task-detail">                          
                <TinyLine {...{
                  height: 60,
                  width: 300,
                  autoFit: false,
                  data: this.state.taskLines.completed,
                  smooth: true,
                  className: "graph",
                }} />  
                <div className="task-count">{this.state.taskCount.completed}</div>
                <div className="task-status">Completed</div>
                </div>          
            </Col>
            <Col xs={24} sm={8} md={8} lg={8}>
            <div className="task-detail">
                <TinyLine {...{
                  height: 60,
                  width: 300,
                  autoFit: false,
                  data: this.state.taskLines.incompleted,
                  smooth: true,
                  className: "graph",
                }} />  
              <div className="task-count">{this.state.taskCount.incompleted}</div>
              <div className="task-status">In Completed</div>
              </div>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8}>
            <div className="task-detail">
                <TinyLine {...{
                  height: 60,
                  width: 300,
                  autoFit: false,
                  data: this.state.taskLines.inprogress,
                  smooth: true,
                  className: "graph",
                }} />  
                <div className="task-count">{this.state.taskCount.inprogress}</div>
                <div className="task-status">In Progress</div>
                </div>
            </Col>
          </Row>
        </Card>
        <Row gutter={24} className="user-task">
          <Col xs={24} sm={24} md={12} lg={12} className="user-task-col">
            <Card title="Tasks" className="task-list-container" extra={<a href="/dashboard/Task">View More</a>} style={{ width: '100%' }}>
            <List
              itemLayout="horizontal"
              dataSource={this.state.task.data}
              loading={this.state.task.loading}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar>{item.icon}</Avatar>}
                    title={<a href={"/dashboard/Task/edit/" + item.name}>{item.subject}</a>}
                    description={<div className="time"><ClockIcon className="time-icon"/>{(item.exp_end_date) ? <ReactTimeAgo date={this.props.convertToCurrentTimezone(item.exp_end_date).substr(0, 16).replace(/-/g, "/")} locale="en"/> : ""}</div>}
                  />
                  <div className="actionbtngroup">
                    <Link to={"/dashboard/Task/edit/" + item.name}><button shape="circle" className="edit-btn"><EditIcon /></button></Link>
                    <Link to={"/dashboard/Task/view/" + item.name}><button shape="circle" className="view-btn"><ViewIcon /></button></Link>
                  </div>
                </List.Item>
              )}
            />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="user-task-col">
            <Card title="Users" className="task-list-container" style={{ width: '100%' }}>
              <List
                itemLayout="horizontal"
                dataSource={this.state.user.data}
                loading={this.state.user.loading}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={(item.user_image) ? <Avatar src={(item.user_image.indexOf("http") === 0) ? item.user_image : "/erp/" + item.user_image} /> : <Avatar>{item.full_name.match(/\b(\w)/g).join('').toUpperCase()}</Avatar>}
                      title={item.full_name}
                      description={item.name}
                    />
                    <div className="actionbtngroup">
                      <button shape="circle" className="view-btn" hidden={true}><ViewIcon /></button>
                    </div>
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    ...state.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...erpActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    dashboardLanding
  )
);
