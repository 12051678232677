import React, { Component, useRef } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
//import FrappeFetch from "react-native-frappe-fetch";

import { Card, Row, Col, Form, Input, Button, Modal, Spin, Checkbox, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
// import Logo from "../../images/logo.png";
// import Scan from "../../images/barcode-scanning.png";
// import ScanAnimation from "../../images/bar-code.gif";
import Axios from "axios";
import * as erpActions from "../../redux/actions/erp-actions";
import * as authActions from  "../../redux/actions/auth-actions";
import Cookies from 'js-cookie';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';
import VerifySignInModal from './modal.js';

class Login extends Component {
  
  constructor(props) {
    
    super(props);
    if(this.props.getLocalStorage('emp_id') !== null && Cookies.get('sid') !==undefined){
      this.signIn(this.props.getLocalStorage('emp_id') , this.props.getLocalStorage('employee_first_name'));
    }
    this.qrInput = React.createRef();
    this.state = {
      username: '',
      password: '',
      emp_id: "",
      first_name: "",
      submitted: false,
      time: new Date(),
      logging_in: false,
      af: true,
      isVerficationModalShown: false,
      tmp_id: '',
      otp:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
  }

  

  handleChange(e) {
    this.setState({ loginMessage: "" });
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  componentDidMount() {
    document.title = "Login";
    
    //localStorage.clear();
    
    //this.emailInput.focus();
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      time: new Date()
    });
  }

  handleSubmit(e) {
    this.setState({ submitted: true });
    const { username, password } = this.state;
    this.signIn(username, password)
  };

  async signIn(username, password) {

    this.setState({ logging_in: true });

    var props = this.props;
    var bodyFormData = new FormData();
    //bodyFormData.set('cmd', 'login');
    bodyFormData.set('usr', username);
    bodyFormData.set('pwd', password);
    //bodyFormData.set('device', 'desktop');

    Axios({
      method: 'post',
      url: process.env.REACT_APP_ERPNEXT_URL + '/api/method/login',
      data: {
        "usr": username,
        "pwd": password,
      },
      withCredentials: true ,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
    .then((response) => {
      this.setState({ logging_in: false });
      if (response.status == 200) {
        console.log(response);
        if (response.data.message === "Logged In") {
          localStorage.setItem("full_name", response.data.full_name);
          localStorage.setItem("name", this.state.username);
          notification['success']({
            message: 'Logged in',
            description: '',
          });

          this.props.getDoc(
            'User',
            this.state.username
          ).then(resp => {
            //data = resp.docs[0]
            this.props.login(resp.docs[0]);
          }).catch(ex => {
            console.log(ex);
          });

          setTimeout(() => this.props.history.push('/dashboard'), 800);
        }else if (response.data.verification.setup === true) {
          //show verfication popup
          this.setState({ isVerficationModalShown: true,tmp_id: response.data.tmp_id});
          
        } else {
          Modal.warning({
            title: 'Warning',
            content: "",
          });
        }
      }
      console.log(response);
    }).catch((error) => {//debugger;
      this.setState({ logging_in: false });
      Modal.warning({
        title: 'Warning',
        content: "Unable to Login. Please check your credentials.",
      });
      console.log(error);
    });

    //await this.props.login(username, password);
    //if (this.props.userData && this.props.userData.redirect !== "")
    //  this.props.history.push(this.props.userData.redirect)

    // administrator
    // rbadmin@RB123
  };

  showVerficationSignInModal = () => {
    this.setState({ isVerficationModalShown: true }, () => {
    this.closeVerficationSignInButton.focus();
  });this.toggleScrollLock();
  };
  closeVerficationSignInModal = () => {
    this.setState({ isVerficationModalShown: false });
    //this.TriggerButton.focus();
    this.toggleScrollLock();
  };
  onKeyDownVerficationSignInModal = (event) => {
    if (event.keyCode === 27) {
      this.closeVerficationSignInModal();
    }
  };
  onClickOutsideVerficationSignInModal = (event) => {
   if (this.verifySignInModal && this.verifySignInModal.contains(event.target)) return;
   this.closeVerficationSignInModal();
  };
  toggleScrollLock = () => {
   document.querySelector('html').classList.toggle('scroll-lock');
  };
  handleChangeVerficationSignInModal = (e) => {    
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  verifySignIn = (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    this.setState({ logging_in: true });

    var props = this.props;
    const { tmp_id, otp } = this.state;
    var bodyFormData = new FormData();
    bodyFormData.set('cmd', 'login');
    bodyFormData.set('tmp_id', tmp_id);
    bodyFormData.set('otp', otp);
    //bodyFormData.set('device', 'desktop');
    var querystring = require('querystring')
    Axios.post(process.env.REACT_APP_ERPNEXT_URL + '/', querystring.stringify({     
      "cmd":'login',
      "tmp_id":tmp_id,
      "otp":otp
    }),
    { headers :{
      'Cookie':'sid=Guest; system_user=yes; full_name=Guest; user_id=Guest; user_image=; LDSERVERID=smb-erpnext; tmp_id='+tmp_id,
      "Content-Type": "application/x-www-form-urlencoded",
      "Accept": "application/json"
    },withCredentials: true})
    .then((response) => {
      this.setState({ logging_in: false });
      if (response.status == 200) {
        console.log(response);
        if (response.data.message === "No App") {
          localStorage.setItem("full_name", response.data.full_name);
          localStorage.setItem("name", this.state.username);
          notification['success']({
            message: 'Logged in',
            description: '',
          });
          setTimeout(() => this.props.history.push('/dashboard'), 800);
        } else {
          Modal.warning({
            title: 'Warning',
            content: "",
          });
        }
      }
      console.log(response);
    }).catch((error) => {//debugger;
      this.setState({ logging_in: false });
      Modal.warning({
        title: 'Warning',
        content: "Unable to Login. Please check your credentials.",
        destroyOnClose: true
      });
      console.log(error);
    });
return false;
    //await this.props.login(username, password);
    //if (this.props.userData && this.props.userData.redirect !== "")
    //  this.props.history.push(this.props.userData.redirect)

    // administrator
    // rbadmin@RB123
  };

  setTempData = (emp_id, first_name) => {
    localStorage.setItem("emp_id_submitted", emp_id);
    localStorage.setItem("first_name_submitted", first_name);
  };
  unsetTempData = () => {
    localStorage.removeItem("emp_id_submitted");
    localStorage.removeItem("first_name_submitted");
  };

  render() {
    const onFinish = values => {
      console.log('Success:', values);
    };
  
    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };
    const tailLayout = {
      wrapperCol: {
        offset: 8,
        span: 16,
      },
    };

    return (
      <React.Fragment>
      <div className="login-section">
        <Card
          title="SMB"
          extra={<h1>Login</h1>}
          bordered={false}
        >
          <h2>Welcome</h2>
          <Spin spinning={this.state.logging_in}>
            <Form
              // {...layout}
              // name="basic"
              // initialValues={{
              //   remember: true,
              // }}
              onFinish={this.handleSubmit}
              onFinishFailed={onFinishFailed}
              style={{width: '600px', margin: '0 auto'}}
            >
              <Form.Item
                name="username"
                onChange={this.handleChange}
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}
              >
                <Input name="username" placeholder="Username" prefix={<UserOutlined />}/>
              </Form.Item>

              <Form.Item
                name="password"
                onChange={this.handleChange}
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password name="password" placeholder="Password" prefix={<LockOutlined />} />
              </Form.Item>

              <Form.Item className="forgot-link">
                <Link to="/forgot-password" className="login-form-forgot">Forgot password</Link>
              </Form.Item>

              <Form.Item 
                //{...tailLayout}
                className="form-button-container"
              >
                <Button 
                  type="primary" 
                  htmlType="submit"
                  onSubmit={this.handleSubmit}
                >
                  Login
                </Button>
              </Form.Item>
              
            </Form>
          </Spin>
        </Card>
      </div>
      
      {this.state.isVerficationModalShown ? (
        <VerifySignInModal
        onSubmit={this.verifySignIn}
        modalRef={(n) => (this.verifySignInModal = n)}
        buttonRef={(n) => (this.closeVerficationSignInButton = n)}
        closeModal={this.closeVerficationSignInModal}
        onKeyDown={this.onKeyDownVerficationSignInModal}
        onClickOutside={this.onClickOutsideVerficationSignInModal}
        handleChange={this.handleChangeVerficationSignInModal}
        />
      ) : null}
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  //loginErrorMessage: PropTypes.string,
  redirect: PropTypes.string,
  userData: PropTypes.object
};


function mapStateToProps(state) {
  return {
    ...state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...erpActions,...authActions }, dispatch);
}

export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(Login)
);
