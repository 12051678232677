import React, { Component } from "react";
import { Switch, withRouter, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import routeOptions from "../app/routes/route";
import PrivateRoute from "./private-routes.jsx";
import { Layout } from "antd";
import { bindActionCreators } from "redux";
import UserHeader from "../app/components/header/header";
import Sidebar from "../app/components/sidebar/sidebar";
import * as sidebarAction from "../app/redux/actions/sidebar-actions";
import Footer from "../app/components/footer/footer";
import "./App.less";
const { Header, Sider, Content } = Layout;
class App extends Component {
  render() {
    let routes = routeOptions.routes.map(({ path, component, exact }, i) => (
      <Route
        key={Math.random() + "ROUTE_"}
        exact={exact}
        path={path}
        component={component}
      />
    ));

    let privateRoute = routeOptions.private.map(
      ({ path, component, exact, roles, status }, i) => (
        <PrivateRoute
          key={Math.random() + "REDIRECT_"}
          roles={roles}
          exact={exact}
          path={path}
          component={component}
          status={status}
        />
      )
    );

    const _pathname = this.props.location.pathname;
    let showHeaderFooter = true;
    if (
      _pathname.indexOf("login") > -1 ||
      _pathname.indexOf("signup") > -1 ||
      _pathname.indexOf("forgot-password") > -1
    ) {
      showHeaderFooter = false;
    }
    return (
      <div className="app-container App">
        <Switch>
          {routes}
          {privateRoute}
        </Switch>
      </div>
    );
  }
}

App.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object
};
function mapStateToProps(state) {
  return {
    ...state.sidebar
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...sidebarAction }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(App)
);
