import React, { Component, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactTimeAgo from 'react-time-ago';

import { 
  Table, Divider, Tag,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  Card,  Modal, Form,Select,Input } from "antd";

// import Axios from "axios";
import { bindActionCreators } from "redux";
import * as erpActions from "../../../redux/actions/erp-actions";

import {ReactComponent as EditIcon} from "../../../images/edit.svg";
import {ReactComponent as ViewIcon} from "../../../images/eye.svg";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
class TeamReview extends Component {
  
  formRef = React.createRef();
  
  state = {
    loading: false,
    doctype: "Task",
    fields: ["subject", "name", "type", "owner", "_assign", "creation", "modified", "modified_by", "status", "docstatus","is_satisfactory"],
    data: [],
    pagination: {
      pageSize: 10,
      current: 1,
      hideOnSinglePage: true,
      showTotal: (total, range) => <span>Showing {range[0]}-{range[1]} of {total}</span>
    },
    sorter: {
      field: "modified",
      order: "descend"
    },
    filters: [],//["[\"Task\",\"status\",\"=\",\"Completed\"]"],
    visible:false
  };

  
  

  constructor(props) {
    
    super(props);
    
  }

  async componentDidMount() {
    document.title = "All " + this.state.doctype;
    if (this.state.loading === false) {
      this.setState({
        loading: true
      });
      this.handleTableChange(this.state.pagination, {}, this.state.sorter);
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      pagination: pagination,
      sorter: sorter,
      loading: true
    });

    this.props.getCount({
      doctype: this.state.doctype,
      filters: "[" +this.state.filters+ "]",
    }).then(resp => {
      const pagination = { ...this.state.pagination };
      pagination.total = resp.total_count;
      this.setState({pagination});

      this.props.getList({
        doctype: this.state.doctype,
        fields: "[" + this.state.fields.map(field => "\"`tab" + this.state.doctype + "`.`" + field + "`\"") + "]",
        filters: "[" +this.state.filters+ "]",
        order_by: (sorter.field) ? "`tab" + this.state.doctype + "`.`" + sorter.field + "` " + ((sorter.order === "ascend") ? "asc" : "desc") : "",
        start: pagination.pageSize * (pagination.current - 1),
        page_length: pagination.pageSize,
        with_comment_count: true,
      }).then(resp => {
        this.setState({
          loading: false,
          data: resp,
        });
        //setData(this.state.data);
      }).catch(ex => {
        this.setState({ loading: false });
      });
    }).catch(ex => {
      this.setState({ loading: false });
    });
  };


  showModal = (taskName,tableDataIndex) => {    
    this.setState({ visible: true });
    if(this.formRef.current){
      this.formRef.current.setFieldsValue({
        'taskName':  taskName,
        'tableDataIndex': tableDataIndex
      });
    }
  }

  handleSubmit = (values) => {
    this.setState({ visible: false });
    console.log(values)
    this.props.saveField('is_satisfactory', values.review,'Task',values.taskName).then(response => {
      var taskDoc = response.data.message;
      this.state.data[values.tableDataIndex].is_satisfactory = taskDoc.is_satisfactory;
      //setData(this.state.data);
    },error => {
      this.setState({ loading: false });
      console.log("error", error);
      Modal.warning({
        title: 'Error',
        content: "Not updated!!",
      });
    }).catch(error => {
      this.setState({ loading: false });
      console.log("||Error: ", error);
      Modal.warning({
        title: 'Error',
        content: "Not updated!",
      });
    });
  }
  
  handleCancel = () => {
    this.setState({ visible: false });
    this.formRef.current.resetFields()
  };
  submitForm = () => {
    this.formRef.current.submit();
};





render() {

   //const [visible, setVisible] = this.state;
   
   const columns = [
     {
       title: "Subject",
       dataIndex: "subject",
       key: "subject",
       sorter: true,
     },
     {
       title: "Status",
       dataIndex: "status",
       key: "status",
     },
     {
       title: "Activity Type",
       dataIndex: "type",
       key: "type",
       render: (text, record) => ( 
         (text) ? text : "No"
       ),
     },
     {
       title: "ID",
       dataIndex: "name",
       key: "name",
       sorter: true,
     },
     {
       title: "Created",
       dataIndex: "creation",
       key: "creation",
       sorter: true,
       //render: (text) => <ReactTimeAgo date={text} locale="en"/>,
       render: (text, record) => (         
         <ReactTimeAgo date={this.props.convertToCurrentTimezone(record.creation).substr(0, 16).replace(/-/g, "/")} locale="en"/>
       ),
     },
     {
       title: "Modified",
       dataIndex: "modified",
       key: "modified",
       sorter: true,
       //render: (text) => <ReactTimeAgo date={text} locale="en"/>,
       render: (text, record) => (        
         <ReactTimeAgo date={this.props.convertToCurrentTimezone(record.modified).substr(0, 16).replace(/-/g, "/")} locale="en"/>
       ),
       //width: "15%",
     },
     {
      title: "Satisfactory",
      dataIndex: "is_satisfactory",
      key: "is_satisfactory",
      sorter: true,
    },
     {
       title: 'Actions',
       key: 'action',
       render: (text, record, index) => ( 
        <span>
          <div className="actionbtngroup">            
            <Link to={"/dashboard/TeamReview/view/"+record.name}><button className="view-btn"><ViewIcon /></button></Link>
          </div>
        </span>
       ),
       //width: "10%",
     },
   ];

   return (
    <div>
      <div className="main-content">
        <div className="task-section">
          <Row gutter={24} className="page-header-section">
            <Col className="page-title"><h1>Team Review</h1></Col>            
          </Row>
          <div className="task-list">
          <Table 
            columns = {columns}
            bordered
            rowKey = {record => record.orderId}
            dataSource = {this.state.data}
            pagination = {this.state.pagination}
            loading = {this.state.loading}
            onChange = {this.handleTableChange}
          />
          </div>
        </div>
            
      </div>      
      <Modal visible={this.state.visible} onOk={this.submitForm} onCancel={this.handleCancel}>
      <Form ref={this.formRef} onFinish={this.handleSubmit}>
        <Form.Item
                  name="review"
                  label="Review"
                >
                  <Select> 
                    <Option value="Yes">Satisfactory</Option>
                    <Option value="No">Not Satisfactory</Option>                          
                  </Select>
        </Form.Item>
        <Form.Item name="taskName" hidden={true}>
    <Input type="text" />
</Form.Item>
<Form.Item name="tableDataIndex" hidden={true}>
    <Input type="text" />
</Form.Item>
        
      </Form>
      </Modal>
    </div>

   );
 }

}

function mapStateToProps(state) {
  return {
    ...state.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...erpActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    TeamReview
  )
);

