import {
    Types
} from "../constants/barcode-list-types";

var initialState = {
    barcodeList : [],
    emplyeeBarcodeList:[]
};

var barcodeList = localStorage.getItem("barcodeList");
var emplyeeBarcodeList = localStorage.getItem("emplyeeBarcodeList");


if( barcodeList && barcodeList.length > 0){
   
    initialState.barcodeList =barcodeList.split(",");
}
if( emplyeeBarcodeList && emplyeeBarcodeList.length > 0){
     initialState.emplyeeBarcodeList = emplyeeBarcodeList.split(",");
}



export default function barcodeListReducer(state = initialState, action) {   
    switch (action.type) {
        case Types.ADD:
            var data = action.payload;
            var barcodeList = state.barcodeList;
            if(barcodeList.indexOf(data) === -1) {
                barcodeList.push(data);
            }
            state.barcodeList = barcodeList;
            localStorage.setItem('barcodeList',barcodeList.join());
            return {
                ...state, 
            };
        case Types.REMOVE:
            var data = action.payload;
            var barcodeList = state.barcodeList;
            var index = barcodeList.indexOf(data);
            if (index > -1) {
                barcodeList.splice(index, 1);
            }
            state.barcodeList = barcodeList;
            localStorage.setItem('barcodeList',barcodeList.join());
            return {
                ...state, 
            };
        case Types.REMOVEMULTI:
            var data = action.payload;
            var barcodeList = state.barcodeList;
            var newBarcodeList =  barcodeList.filter(function(element) {
                return data.indexOf(element) === -1;
            });
            state.barcodeList = newBarcodeList;
            localStorage.setItem('barcodeList',newBarcodeList.join());
            return {
                ...state, 
            };
        case Types.CLEAR:
            state.barcodeList = [];
            localStorage.removeItem('barcodeList');
            return {
                ...state, 
            };
        case Types.EMPADD:
            var data = action.payload;
            var emplyeeBarcodeList = state.emplyeeBarcodeList;
            if(emplyeeBarcodeList.indexOf(data) === -1) {
                emplyeeBarcodeList.push(data);
            }
            state.emplyeeBarcodeList = emplyeeBarcodeList;
            localStorage.setItem('emplyeeBarcodeList',emplyeeBarcodeList.join());
            return {
                ...state, 
            };
        case Types.EMPREMOVE:
            var data = action.payload;
            var emplyeeBarcodeList = state.emplyeeBarcodeList;
            var index = emplyeeBarcodeList.indexOf(data);
            if (index > -1) {
                emplyeeBarcodeList.splice(index, 1);
            }
            state.emplyeeBarcodeList = emplyeeBarcodeList;
            localStorage.setItem('emplyeeBarcodeList',emplyeeBarcodeList.join());
            return {
                ...state, 
            };
        case Types.EMPREMOVEMULTI:
            var data = action.payload;
            var emplyeeBarcodeList = state.emplyeeBarcodeList;
            var newBarcodeList =  emplyeeBarcodeList.filter(function(element) {
                return data.indexOf(element) === -1;
            });
            state.emplyeeBarcodeList = newBarcodeList;
            localStorage.setItem('emplyeeBarcodeList',newBarcodeList.join());
            return {
                ...state, 
            };
        case Types.EMPCLEAR:
            state.emplyeeBarcodeList = [];
            localStorage.removeItem('emplyeeBarcodeList');
            return {
                ...state, 
            };
        default:
            return state;
    }
}