import React from 'react';export const Form = ({ onSubmit, handleChange}) => {
    return (
      <React.Fragment>
        <h2>Verfication email sent</h2>
        <h3>Please enter code below</h3>
    <form onSubmit={onSubmit}>
        <div className="form-group">
        {/* <label htmlFor="name">Code</label> */}
        <input type="text" className="form-control" placeholder="Code" id="otp" name="otp" onChange={handleChange} />
      </div>
             
      
      <div className="form-group">
        <button className="form-control btn btn-primary" type="submit">
          Submit
        </button></div>
    </form>
    </React.Fragment>
    );
    };
    export default Form;