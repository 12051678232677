import React, { Component } from "react";
import {
  Form,
  Input,
  notification,
  Cascader,
  Spin,
  Row,
  Col,
  Descriptions,
  Button,
  Modal,
  Breadcrumb,
  Card,
  message, Upload
} from 'antd';
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as erpActions from "../../../redux/actions/erp-actions";
import { PlusOutlined } from '@ant-design/icons';
import { getBase64 } from "../../../utilities";

const {confirm} = Modal;

class ViewDoc extends Component {


  constructor(props) {
    super(props);
    this.state = {
      data: {},
      docinfo: {},
      doctype: "Issue",
      name: "",
      previewVisible: false,
      previewImage: '',
      previewFileUrl:'',
      previewTitle: '',
      fileList: [],
    };
    
    const { params: { name } } = props.match;

    document.title = "View Ticket: " + name;

    this.setState({name: name, loading: true});

    this.props.getDoc(
        this.state.doctype,
        name
      ).then(resp => {
        this.setState({
          loading: false,
          data: resp.docs[0],
          docinfo: resp.docinfo
        });
        var newArray = this.state.fileList.slice();  
        resp.docinfo.attachments.forEach(fileObj => {
          newArray.push( {
            uid: fileObj.name,
            name: fileObj.file_name,
            status: 'done',
            url: 'erp/'+fileObj.file_url,
          }); 
        });          
        this.setState({fileList:newArray})
      }).catch(ex => {
        this.setState({ loading: false });
      });
  }

  changeStatus = (status) => {
    
    confirm({
      title: 'Do you want to change ticket status to ' + status + '?',
      //icon: <ExclamationCircleOutlined />,
      content: '',
      onOk: () => {
        this.setState({ loading: true });
        this.props.saveDoc({...this.state.data, status: status}, "Save").then(response => {
          if(response.status === 200) {
            notification['success']({message: 'Data Saved',description: 'Ticket status is now updated.'});
            setTimeout(() => this.props.history.push(("/dashboard/" + this.state.doctype + "/view/" + response.data.docs[0].name), 1500));
          } else {
            this.setState({ loading: false });
            if( (response.data !== undefined) && (response.data._server_messages !== undefined) ) {
              var message = JSON.parse(JSON.parse(response.data._server_messages.replace(/<\/?[^>]+(>|$)/g, ""))[0]).message;
              debugger;
            } else {
              var message = "Unexpected error occured";
            }
            Modal.warning({
              title: 'Error',
              content: message,
              //onOk: function(){ props.history.push(("/dashboard/" + this.state.doctype); },
            });
          }
        }
      )},
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  handleUploadCancel = () => this.setState({ previewVisible: false });

  handleUploadPreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

 

    this.setState({
      previewImage: file.name.match(/\.(jpg|jpeg|png|gif)$/) ? (file.url || file.preview) : null,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      previewFileUrl:file.url
    });
  };

  render() {
    const { previewVisible, previewImage, fileList, previewTitle,previewFileUrl } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <div id="select-process" className="main-content">
        <div className="create-doc-section">
          <Row gutter={24} className="page-header-section">
            <Col className="page-title">
              <Row gutter={24} className="page-header-section">
                <Col className="page-title">
                  <Breadcrumb>
                    <Breadcrumb.Item><Link to={"/dashboard/" + this.state.doctype}><h1>Ticket</h1></Link></Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span>View Ticket</span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
                <Col>
                  {(this.state.data.status === "Open")?
                    <Button type="primary" size="small" onClick={() => this.changeStatus("Closed")}>Close</Button>
                    : <Button type="primary" size="small" onClick={() => this.changeStatus("Open")}>Reopen</Button>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          <Card>

            <Spin spinning={this.state.loading}>
              <Descriptions title="Details" labelStyle={{fontWeight: "600"}} contentStyle={{color: "#74767a"}}>
                <Descriptions.Item label="Subject">{this.state.data.subject}</Descriptions.Item>
                <Descriptions.Item label="Status">{this.state.data.status}</Descriptions.Item>
                <Descriptions.Item label="Type">{this.state.data.issue_type}</Descriptions.Item>
                <Descriptions.Item label="Priority">{this.state.data.priority}</Descriptions.Item>
                {/* <Descriptions.Item label=""></Descriptions.Item> */}
                <Descriptions.Item label="Description"><div dangerouslySetInnerHTML={ {__html: this.state.data.description} } /></Descriptions.Item>
              </Descriptions>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} className="align-left-5">
                    Attachments
                    <Upload
          disabled={true}
          style={{ display: 'none' }}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handleUploadPreview}          
        >0
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleUploadCancel}
        >
          {
          (previewImage) ? 
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
          :
          <iframe src={previewFileUrl} style={{ width: '100%' }}/>
  }
        </Modal>
                  </Col>
                </Row>
            </Spin>
          </Card>
        </div>
      </div>
    );
  }
}


ViewDoc.defaultProps = {
  showActionTab: true
};

function mapStateToProps(state) {
  return {
    ...state.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...erpActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ViewDoc
  )
);