import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Layout } from "antd";
import UserHeader from "../header/header.jsx";
import Sidebar from "../sidebar/sidebar";
import * as sidebarAction from "../../redux/actions/sidebar-actions";

const { Header, Sider, Content } = Layout;

class UserDash extends Component {
  render() {
    return (
      <div id="components-layout-demo-custom-trigger">
        <Layout>
          <Sider
            trigger={null}
            collapsible
            collapsed={!this.props.sidebar}
            style={{
              overflow: "auto",
              height: "100vh",
              position: "sticky",
              top: 0,
              left: 0
            }}
            width={200}
          >
            <Sidebar />
          </Sider>
          <Layout>
            <Header
              className="header"
              style={{
                background: "#fff",
                position: "sticky",
                zIndex: 100,
                top: 0,
                left: 0,
                width: "100%",
                paddingLeft: "20px"
              }}
              location={this.props.location}
              history={this.props.history}
            >
              <UserHeader />
            </Header>
            <Content
              style={{
                margin: "24px 16px",
                padding: 24,
                background: "#fff",
                minHeight: 280
              }}
            ></Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

UserDash.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};
function mapStateToProps(state) {
  return {
    ...state.sidebar
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...sidebarAction }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    UserDash
  )
);
