import React, { Component, useRef } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import { Card, Row, Col, Form, Input, Button, Modal, Spin, Checkbox, notification } from "antd";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as erpActions from "../../redux/actions/erp-actions";
import * as authActions from  "../../redux/actions/auth-actions";
import Cookies from 'js-cookie';

class Login extends Component {
  
  constructor(props) {
    
    super(props);
    if(this.props.getLocalStorage('emp_id') !== null && Cookies.get('sid') !==undefined){
      this.signIn(this.props.getLocalStorage('emp_id') , this.props.getLocalStorage('employee_first_name'));
    }
    this.qrInput = React.createRef();
    this.state = {
      username: '',
      password: '',
      emp_id: "",
      first_name: "",
      submitted: false,
      time: new Date(),
      logging_in: false,
      af: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
  }

  handleChange(e) {
    this.setState({ loginMessage: "" });
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  componentDidMount() {
    document.title = "Forgot Password";
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      time: new Date()
    });
  }

  handleSubmit(e) {
    this.setState({ submitted: true });
    const { username, password } = this.state;
    this.signIn(username, password)
  }

  render() {

    return (
      <div className="login-section">
        <Card
          title="SMB"
          extra={<h1>Login</h1>}
          bordered={false}
        >
          <h2>Forgot Password</h2>
          <Form
            style={{width: '600px', margin: '0 auto'}}
          >
            <Form.Item
              name="email-id"
              onChange={this.handleChange}
              rules={[
                {
                  required: true,
                  message: 'Please input your email id!',
                },
              ]}
              className="input-email"
            >
              <Input name="email-id" placeholder="Email Id" prefix={"@"}/>
            </Form.Item>

            <Form.Item 
              className="form-button-container"
            >
              <Button 
                type="primary" 
                htmlType="submit"
                onSubmit={this.handleSubmit}
              >
                Submit
              </Button>
            </Form.Item>
            
          </Form>
        </Card>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  //loginErrorMessage: PropTypes.string,
  redirect: PropTypes.string,
  userData: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...erpActions,...authActions }, dispatch);
}

export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(Login)
);
